import React from 'react';
import { groupBy, includes } from 'lodash/fp';
import { PrimaryButton } from './';
import { ImportFile } from 'data/models/types/import-files';
import useErrorModal from 'src/hooks/use-error-modal';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes/app';

export interface SubmitButtonProps {
  importId: string;
  files: ImportFile[];
}

const SubmitButton = ({ files, importId }: SubmitButtonProps) => {
  const [Error, openError] = useErrorModal();

  const onSubmit = () => {
    // Group files into categories
    const grouped = groupBy((f: ImportFile) => {
      const includesStatus = includes(f.status?.id);

      if (includesStatus(['blocking_error'])) {
        return 'blocking';
      }

      if (includesStatus(['error', 'warning'])) {
        return 'warning';
      }

      if (includesStatus(['complete'])) {
        return 'complete';
      }

      return 'pending';
    })(files);

    if (files.length === 0) {
      openError({
        title: 'Your files aren\'t ready for import yet',
        content: 'You must upload a file before submitting.'
      });
      return;
    }

    if (grouped.pending?.length > 0) {
      // Pending
      openError({
        title: 'Your files aren\'t ready for import yet',
        content:
          'We\'re validating your files at the moment. Please wait until validation is complete before submitting.'
      });
      return;
    }

    if (grouped.blocking?.length > 0) {
      // Blocking errors
      openError({
        title: 'Your files aren\'t ready for import yet',
        content:
          'Some of your files have blocking errors that prevent us from importing. Please fix these issues and then try again.'
      });
      return;
    }

    // Redirect to submit
    push(ROUTES.SUBMIT, {
      params: {
        importId: importId
      }
    });
  };

  return (
    <>
      <PrimaryButton grey onClick={onSubmit}>
        Submit
      </PrimaryButton>
      <Error />
    </>
  );
};

export default SubmitButton;
// export default withWhereabouts()(SubmitButton);
