import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      fetchList: (type, { importId, ...args }) =>
        api.get(`/imports/${importId}/files`, args),
      fetchItem: (type, { importId, ...args }, id) =>
        api.get(`/imports/${importId}/files/${id}`, args),
      createItem: (type, { importId, ...args }) =>
        api.post(`/imports/${importId}/files`, args),
      updateItem: (type, { importId, ...args }, id) =>
        api.patch(`/imports/${importId}/files/${id}`, args),
      trashItem: (type, { importId, ...args }, id) =>
        api.delete(`/imports/${importId}/files/${id}`, args)
    }
  }
};

const actionCreators = {
  download: {
    request: ({ importId, fileId }) =>
      api.get(`/imports/${importId}/files/${fileId}/download`),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ImportFileGenerator = new Generator('importFiles', config);
export default ImportFileGenerator.createEntityModel({ actionCreators });
