import Generator from 'data/models/generator';
import _ from 'lodash';
import { api, setAuthToken, setWingsToken, wings } from 'utils/api-client';
import Analytics from '@rexlabs/analytics';

export const TYPE = 'session';

const initialState = {
  ready: false,
  apiToken: null,
  rexApiToken: null,
  rexAccountId: null,
  rexName: null,
  user: null,
  admin: false
};

const selectors = {
  ready: state => state.session.ready,
  apiToken: state => state.session.apiToken,
  rexApiToken: state => state.session.rexApiToken,
  rexName: state => state.session.rexName,
  user: state => state.session.user,
  admin: state => state.session.admin,
  rexAccountId: state => state.session.rexAccountId
};

const actionCreators = {
  login: {
    request: (payload, actions, dispatch, getState) =>
      new Promise(async (resolve, reject) => {
        const loginReq = await api
          .post('/access-tokens', {
            email_address: payload.email,
            password: payload.password,
            type: 'password'
          })
          .then(_.identity)
          .catch(reject);

        const login = loginReq.data;

        if (!login || !login.access_token || !login.rex_token) {
          reject(new Error('Login failed'));
          return;
        }
        setAuthToken(login.access_token);
        setWingsToken(login.rex_token);

        const userProfile = await getUserProfile()
          .then(_.identity)
          .catch(reject);

        resolve({
          apiToken: login.access_token,
          rexApiToken: login.rex_token,
          rexAccountId: login.rex_account_id,
          admin: login.user.is_system_admin,
          rexName: userProfile.first_name
        });
      }),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        apiToken: action.payload.apiToken,
        rexApiToken: action.payload.rexApiToken,
        rexAccountId: action.payload.rexAccountId ?? null,
        admin: action.payload.admin,
        rexName: action.payload.rexName
      }),
      failure: state => ({
        ...state,
        apiToken: null,
        rexApiToken: null,
        rexName: null,
        admin: false
      })
    }
  },

  switch: {
    request: (payload, actions, dispatch, getState) =>
      new Promise(async (resolve, reject) => {
        const switchReq = await api
          .post('/access-tokens/switch-account', {
            account_id: payload.rex_account_id
          })
          .then(_.identity)
          .catch(reject);

        const resp = switchReq.data;

        if (
          !resp ||
          !resp.access_token ||
          !resp.rex_token ||
          !resp.rex_account_id
        ) {
          reject(new Error('Failed to switch account'));
        }

        setAuthToken(resp.access_token);
        setWingsToken(resp.rex_token);

        const userProfile = await getUserProfile()
          .then(_.identity)
          .catch(reject);

        resolve({
          apiToken: resp.access_token,
          rexApiToken: resp.rex_token,
          admin: resp.user.is_system_admin,
          rexName: userProfile.first_name,
          rexAccountId: resp.rex_account_id
        });
      }),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        rexAccountId: action.payload.rexAccountId,
        apiToken: action.payload.apiToken,
        rexApiToken: action.payload.rexApiToken,
        admin: action.payload.admin,
        rexName: action.payload.rexName
      }),
      failure: state => ({
        ...state,
        rexAccountId: null,
        apiToken: null,
        rexApiToken: null,
        admin: false,
        rexName: null
      })
    }
  },

  logout: {
    reduce: (state, action) => ({
      ...state,
      user: null,
      apiToken: null,
      rexApiToken: null,
      rexAccountId: null,
      rexName: null,
      admin: false
    })
  },

  init: {
    request: (payload, actions, dispatch, getState) =>
      initSession(getState().session),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        admin: action?.payload?.['is_system_admin'] ?? false,
        ready: true
        // Enable when we start to populate user data
        // user: action.payload.user
      }),
      failure: state => ({
        ...state,
        ready: true,
        apiToken: null,
        rexApiToken: null,
        rexAccountId: null,
        admin: false
      })
    }
  }
};

function getUserProfile () {
  return wings('UserProfile::read', {});
}

function initSession ({ apiToken, rexApiToken, rexName, userId }) {
  return new Promise((resolve, reject) => {
    // Check api token and set token and org for api client here
    if (!apiToken) {
      reject(new Error('No API token found!'));
      return;
    }

    setAuthToken(apiToken);
    setWingsToken(rexApiToken);

    if (userId) {
      Analytics.identify({
        userId: userId,
        rexName: rexName
      });
    }

    return Promise.all([api.get('/users/me'), getUserProfile()])
      .then(res => {
        const springRes = res?.[0];
        resolve(springRes?.data ?? springRes);
      })
      .catch(reject);
  });
}

const session = new Generator(TYPE).createModel({
  initialState,
  selectors,
  actionCreators
});

export default session;
