import React from 'react';
import { COLORS } from 'theme';
import Modal from 'view/components/modal/modal';

export interface ErrorModalProps {
  title: string;
  error: boolean;
  closeText: string;
  onClose: (...args: any) => any;
  children: React.ReactNode;
}

const ErrorModal = ({ title, onClose, children }: ErrorModalProps) => (
  <Modal
    title={title || 'An Error Occurred'}
    closeModal={onClose}
    color={COLORS.RED}
  >
    {children}
  </Modal>
);

export default ErrorModal;
