import React, { useState } from 'react';
import Modal from 'view/components/modal/modal';
import ImportFileForm, { ImportFileFormValues } from 'view/forms/import-file';
import { api } from 'utils/api-client';
import { Entity } from 'data/models/types';
import { ImportFile } from 'data/models/types/import-files';
import { useErrorModal } from './';

const useUpdateImportFileForm = (
  importFile: Entity<ImportFile>,
  file: ImportFile
) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [Error, open] = useErrorModal();

  const updateFile = (values: ImportFileFormValues) => {
    // Upload new file
    const data = new FormData();
    data.append('file', values.file[0]);

    return api
      .post('/files', data)
      .then(async (res: any) => {
        // Add new file to import file
        const fileId = res?.data?.id;
        await importFile
          .updateItem({
            id: file?.id,
            args: {
              importId: file?.import?.id
            },
            data: {
              file_id: fileId,
              type_id: values.type
            }
          })
          .then(() => setShowForm(false))
          .catch(handleError);
      })
      .catch(handleError);
  };

  const handleError = (e: Error) =>
    open({
      title: 'Something Happened',
      content: e.message
    });

  const FormModal = () => (
    <>
      {showForm && (
        <Modal
          title={'Update Spreadsheet'}
          closeModal={() => setShowForm(false)}
        >
          <ImportFileForm handleSubmit={updateFile} importFile={file} />
        </Modal>
      )}
      <Error />
    </>
  );

  return [FormModal, setShowForm];
};

export default useUpdateImportFileForm;
