import React from 'react';
import fp from 'lodash/fp';
import { Row, Cell, HeaderCell } from './';
import { ImportFile } from 'data/models/types/import-files';
import { PrimaryButton } from 'view/components/button';
import { useModal } from 'src/hooks';
import FileSettings from '../settings';

export interface SubmissionBodyProps {
  importFile: ImportFile;
  isSubmitted?: boolean;
}

const SubmissionHeader = ({ isSubmitted = false }) => {
  return (
    <Row>
      <HeaderCell width={'13rem'}>Type</HeaderCell>
      <HeaderCell width={'25%'}>Name</HeaderCell>
      <HeaderCell flex={1}>Find Duplicates</HeaderCell>
      <HeaderCell flex={1}>Handle Duplicates</HeaderCell>
      <HeaderCell flex={1}>Permission</HeaderCell>
      {!isSubmitted && <HeaderCell flex={1}>&nbsp;</HeaderCell>}
    </Row>
  );
};

const SubmissionBody = ({
  importFile,
  isSubmitted = false
}: SubmissionBodyProps) => {
  const [SettingModal, openSettingModal, closeSettingModal] = useModal({
    content: (
      <FileSettings
        onSubmit={() => closeSettingModal()}
        importFile={importFile}
      />
    ),
    title: 'Import Settings'
  });

  const getFindDuplicateLabel = (type?: string) => {
    if (type === 'both') return 'Phone Number & Email Address';
    if (type === 'phone_number') return 'Phone Number';
    if (type === 'email_address') return 'Email Address';
    if (type === 'company_name') return 'Company Name';
    if (type === 'insert') return 'Insert Duplicates';
    return '-';
  };

  return (
    <Row>
      <Cell width={'13rem'}>{importFile.type.label}</Cell>
      <Cell width={'25%'}>{importFile.file.name}</Cell>
      <Cell flex={1}>
        {getFindDuplicateLabel(importFile.settings?.duplicate_detection_type)}
      </Cell>
      <Cell flex={1}>
        {fp.capitalize(importFile.settings?.handle_duplicates ?? '-')}
      </Cell>
      <Cell flex={1}>
        {importFile.settings?.update_record_owners === true
          ? 'Take ownerships'
          : importFile.settings?.update_record_owners === false
          ? 'Don\'t Take ownerships'
          : '-'}
      </Cell>
      {['people', 'companies', 'properties'].includes(importFile.type?.id) &&
        !isSubmitted && (
          <Cell flex={1} justifyContent={'flex-end'}>
            <PrimaryButton onClick={openSettingModal}>
              Edit Settings
            </PrimaryButton>
          </Cell>
        )}
      <SettingModal />
    </Row>
  );
};

export { SubmissionHeader, SubmissionBody };
