import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';

import { PADDINGS } from 'src/theme';
import { Heading, Body } from 'view/components/text';

export interface HeaderProps {
  Icon: React.ReactNode;
  Buttons?: React.ReactNode;
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles = StyleSheet({
  container: {
    paddingBottom: `${PADDINGS.M}`
  },

  wrapHeading: {
    maxWidth: '50rem',
    marginTop: '.6rem'
  },

  icon: {
    marginRight: PADDINGS.S
  }
});

const Header = ({
  styles: s,
  Icon,
  Buttons,
  title,
  subtitle
}: HeaderProps & DecoratorProps) => {
  return (
    <Box
      {...s('container')}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box mb={PADDINGS.L} flexDirection={'row'}>
        <Box {...s('icon')}>{Icon}</Box>
        <Box flexDirection='column' {...s('wrapHeading')}>
          <Heading>{title}</Heading>
          <Body grey>{subtitle}</Body>
        </Box>
      </Box>
      <Box justifyContent={'flex-end'}>{Buttons}</Box>
    </Box>
  );
};

export default styled(defaultStyles)(Header);
