import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Form, FormField, ReactForms } from 'view/components/form';
import PrimaryButton from 'view/components/button/primary';
import { PADDINGS } from 'src/theme';
import { TextInput } from '@rexlabs/text-input';
import RadioGroup from '../components/checkbox/radio-group';
import importsModel from 'data/models/entities/imports';
import { withModel } from '@rexlabs/model-generator';
import { createValidationRules } from 'utils/form';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';

@withModel(importsModel)
class ImportForm extends PureComponent {
  handleSubmit = (values, props) => {
    const { imports } = this.props;
    return imports
      .createItem({
        data: {
          type: {
            id: 'rex_spreadsheet'
          },
          description: values.name,
          schedule: values.importSchedule
        }
      })
      .then(res => {
        push(ROUTES.IMPORT, { params: { id: res.data.id } });
      })
      .catch(console.error);
  };

  validate = createValidationRules({
    name: 'required',
    importType: 'required'
  });

  render () {
    return (
      <ReactForms handleSubmit={this.handleSubmit} validate={this.validate}>
        {({ submitForm, isSubmitting }) => (
          <Form name='upload-file-form'>
            <Box>
              <FormField
                sendImmediate
                label='Name'
                name='name'
                Input={TextInput}
                inputProps={{
                  placeholder: 'Agency Name_User Name'
                }}
              />
              <FormField
                label={'Please select type of import'}
                name='importSchedule'
                Input={RadioGroup}
                inputProps={{
                  items: [
                    {
                      label: (
                        <>
                          Free monthly (These are typically run at the end of
                          each month. Click{' '}
                          <a
                            href='https://support.rexsoftware.com/hc/en-us/articles/360033404693-Monthly-Data-Imports-Free-Paid-'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            here
                          </a>{' '}
                          for more info)
                        </>
                      ),
                      value: 'free'
                    },
                    {
                      label:
                        'Paid unscheduled ($300+GST with a 2 business day turn around)',
                      value: 'paid'
                    }
                  ]
                }}
                initialValue={'free'}
              />
              <Box mt={PADDINGS.M} justifyContent={'flex-end'}>
                <PrimaryButton
                  grey
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  Submit
                </PrimaryButton>
              </Box>
            </Box>
          </Form>
        )}
      </ReactForms>
    );
  }
}

export default ImportForm;
