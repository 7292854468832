/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from 'utils/redux';
import {
  whereaboutsReducer as whereabouts,
  whereaboutsMiddleware
} from '@rexlabs/whereabouts';
import { combineModels } from '@rexlabs/model-generator';

/*
| Core Models
|------------------------
*/
import session from 'data/models/custom/session';
import connection, { middleware } from 'data/models/custom/connection';

/*
 | Entity Models (Model Generator)
 |------------------------
 */
import instance from 'data/models/entities/instances';
import imports from 'data/models/entities/imports';
import files from 'data/models/entities/files';
import importFiles from 'data/models/entities/import-files';
import issues from 'data/models/entities/issues';
import archivedImports from 'data/models/entities/archived-imports';
import submissions from 'data/models/entities/submissions';

/*
| Setup for Store (incl. persistance)
|------------------------
*/
const entities = combineModels('entities', {
  instance,
  imports,
  files,
  importFiles,
  issues,
  archivedImports,
  submissions
});

const persistSessionConfig = {
  key: '@frontend/session',
  storage,
  blacklist: ['ready']
};
const persistSession = persistReducer(persistSessionConfig, session);

const reducers = combineReducers({
  session: persistSession,
  connection,
  whereabouts,
  entities
});

const store = configureStore(reducers, [middleware, whereaboutsMiddleware]);
const persistedStore = persistStore(store, null, () => {
  store.dispatch(session.actionCreators.init());
});

if (__DEV__) {
  window.purgePersistedStore = persistedStore.purge;
}

export { store, persistedStore };
