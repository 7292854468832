import React, { PureComponent } from 'react';
import { CSSRecord } from 'types';
import { Portal } from '@rexlabs/portal';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, BORDER_RADIUS } from 'src/theme';
import { SubHeading } from '../text';

export interface ModalProps {
  title?: string | React.ReactNode;
  closeModal?: (...args: any) => any;
  width?: number | string;
  color?: string;
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles: CSSRecord = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: COLORS.WHITE,
    width: '100%',
    borderTop: `0.4rem solid ${COLORS.BLUE}`,
    borderRadius: BORDER_RADIUS.DIALOGS,
    overflow: 'hidden'
  },
  close: {
    fontWeight: 'normal',
    cursor: 'pointer'
  },
  head: {
    padding: PADDINGS.M,
    fontWeight: 'lighter'
  },
  content: {
    padding: `0 ${PADDINGS.M} ${PADDINGS.M} ${PADDINGS.M}`,
    maxHeight: '80vh',
    overflowY: 'auto'
  }
};

class Modal extends PureComponent<ModalProps & DecoratorProps> {
  render () {
    const {
      styles: s,
      title,
      closeModal,
      width = '80rem',
      color,
      children
    } = this.props;
    const topColor = color || COLORS.BLUE;
    return (
      <Portal target='modals'>
        <Box
          {...s.with('container')({
            maxWidth: width,
            maxHeight: '94vh',
            borderTop: `0.4rem solid ${topColor}`
          })}
        >
          <Box
            {...s('head')}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'middle'}
          >
            <SubHeading>{title}</SubHeading>
            <div onClick={closeModal}>
              <SubHeading {...s('close')} style={{ fontWeight: '300' }}>
                &times;
              </SubHeading>
            </div>
          </Box>
          <Box {...s('content')}>{children}</Box>
        </Box>
      </Portal>
    );
  }
}

export default styled(StyleSheet(defaultStyles))(Modal);
