/* eslint-disable camelcase */

import React, { PureComponent, Fragment } from 'react';
import dayjs from 'dayjs/esm';
import { autobind } from 'core-decorators';
import { push } from '@rexlabs/whereabouts';
import { Link } from '@rexlabs/react-whereabouts';

import { COLORS } from 'theme';
import ROUTES from 'src/routes/app';
import ActionModal from 'view/components/modal/action-modal';
import { ButtonContainer, PrimaryButton } from '../button';
import ErrorModal from 'view/modals/error-modal';

import { withModel } from '@rexlabs/model-generator';
import importsModel from 'data/models/entities/imports';
import archivedImportsModel from 'data/models/entities/archived-imports';

import { Row, Cell, HeaderCell } from './';

class ImportHeader extends PureComponent {
  render () {
    return (
      <Row>
        <HeaderCell flex={1}>Name</HeaderCell>
        <HeaderCell width='20%'>Files</HeaderCell>
        <HeaderCell width='20%'>Created at</HeaderCell>
        <HeaderCell width='23rem'>&nbsp;</HeaderCell>
      </Row>
    );
  }
}
@withModel(importsModel)
@withModel(archivedImportsModel)
@autobind
class ImportItem extends PureComponent {
  state = {
    showArchiveImportsModal: false,
    showRestoreImportsModal: false,
    showDeleteImportsModal: false,
    showSubmittedModal: false
  };

  viewImport () {
    const { item } = this.props;
    push(ROUTES.IMPORT, { params: { id: item.id } });
  }

  archiveImport () {
    const { imports, item } = this.props;
    return imports.updateItem({
      id: item.id,
      args: {
        archived_at: dayjs().format('YYYY-MM-DD HH:mm:ss')
      }
    });
  }

  deleteImport (id) {
    const { imports } = this.props;
    return imports.trashItem({
      id: id
    });
  }

  restoreImport (id) {
    const { imports } = this.props;
    return imports.updateItem({
      id: id,
      args: {
        archived_at: null
      }
    });
  }

  openArchiveModal = () => this.setState({ showArchiveImportsModal: true });
  closeArchiveModal = () => this.setState({ showArchiveImportsModal: false });

  openDeleteModal = () => this.setState({ showDeleteImportsModal: true });
  closeDeleteModal = () => this.setState({ showDeleteImportsModal: false });

  openRestoreModal = () => this.setState({ showRestoreImportsModal: true });
  closeRestoreModal = () => this.setState({ showRestoreImportsModal: false });

  render () {
    const { item, refreshImports } = this.props;
    const {
      showArchiveImportsModal,
      showRestoreImportsModal,
      showDeleteImportsModal,
      showSubmittedModal
    } = this.state;

    const isSubmitted = item.submissions.length > 0;

    return (
      <Fragment>
        <Link to={ROUTES.IMPORT} params={{ id: item.id }}>
          {({ target, onClick }) => (
            <Row Container='a' href={target} onClick={onClick}>
              <Cell flex={1}>{item?.description}</Cell>
              <Cell width='20%'>{item?.files?.data?.length}</Cell>
              <Cell width='20%'>
                {item?.created_at
                  ? dayjs(item?.created_at).format('DD MMM YYYY h:mma')
                  : '—'}
              </Cell>
              <Cell
                width='23rem'
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {item?.archived_at === null && (
                  <ButtonContainer justifyContent='flex-end'>
                    <PrimaryButton onClick={this.viewImport}>
                      View
                    </PrimaryButton>
                    <PrimaryButton onClick={this.openArchiveModal}>
                      Archive
                    </PrimaryButton>
                  </ButtonContainer>
                )}
                {item?.archived_at && (
                  <ButtonContainer justifyContent='flex-end'>
                    <PrimaryButton onClick={this.viewImport}>
                      View
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() =>
                        this.setState({ showRestoreImportsModal: true })
                      }
                    >
                      Restore
                    </PrimaryButton>
                    <PrimaryButton
                      red
                      onClick={() => {
                        if (isSubmitted) {
                          this.setState({ showSubmittedModal: true });
                        } else {
                          this.setState({ showDeleteImportsModal: true });
                        }
                      }}
                    >
                      Delete
                    </PrimaryButton>
                  </ButtonContainer>
                )}
              </Cell>
            </Row>
          )}
        </Link>

        {showArchiveImportsModal && (
          <ActionModal
            title='Archive This Session'
            closeModal={this.closeArchiveModal}
            onConfirm={() => this.archiveImport().then(refreshImports)}
            color={COLORS.RED}
          />
        )}

        {showDeleteImportsModal && (
          <ActionModal
            title='Delete This Session'
            closeModal={this.closeDeleteModal}
            onConfirm={() => this.deleteImport(item.id).then(refreshImports)}
            color={COLORS.RED}
          >
            Are you sure you want to delete this spreadsheet? This cannot be
            undone.
          </ActionModal>
        )}

        {showRestoreImportsModal && (
          <ActionModal
            title='Restore This Session'
            closeModal={this.closeRestoreModal}
            onConfirm={() => this.restoreImport(item.id).then(refreshImports)}
            color={COLORS.GREEN}
          />
        )}

        {showSubmittedModal && (
          <ErrorModal
            error
            onClose={() => this.setState({ showSubmittedModal: false })}
            title={'Cannot Delete'}
          >
            You cannot delete a submitted import.
          </ErrorModal>
        )}
      </Fragment>
    );
  }
}

export { ImportHeader, ImportItem };
