import { query } from '@rexlabs/model-generator';
import importsModel from 'data/models/entities/import-files';

export const listImportFilesQuery = importId => query`{
  ${importsModel} (importId: ${importId}, include: file) {
    id
    file
    type
    status
    import
    is_valid
    notes
    settings
    created_at
    updated_at
    deleted_at
  }
}`;

export const getImportFilesQuery = (importId, fileId) => query`{
  ${importsModel} (importId: ${importId}, id: ${fileId}, include: file) {
    id
    file
    type
    status
    import
    is_valid
    notes
    settings
    created_at
    updated_at
    deleted_at
  }
}`;
