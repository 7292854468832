import React, { Fragment, useState, useEffect } from 'react';
import dayjs from 'dayjs/esm';
import { compose } from 'redux';
import _ from 'lodash';
import Box from '@rexlabs/box';
import { api } from 'utils/api-client';
import Modal from 'view/components/modal/modal';
import { withQuery } from '@rexlabs/model-generator';
import { getImportQuery } from 'data/queries/imports';
import { listImportFilesQuery } from 'data/queries/import-files';
import { Body, SubHeading, Heading } from 'view/components/text';
import ImportFileForm from 'view/forms/import-file';
import List from '@rexlabs/list';
import { PADDINGS } from 'src/theme';
import { echo } from 'utils/echo-client';
import LoadingLayout from 'view/layouts/loading';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton
} from 'view/components/button';
import Breadcrumb from 'view/components/breadcrumb';
import EmptyView from 'view/components/list/empty';
import { FileHeader, FileItem } from 'view/components/list/files';
import SubmitButton from 'view/components/button/submit-button';
import { useErrorModal } from 'src/hooks';
import {
  SubmissionHeader,
  SubmissionBody
} from '../components/list/submission.tsx';

function getImportId (props) {
  return _.get(props, 'match.params.id');
}

const _ImportScreen = ({ imports, importFiles, ...props }) => {
  const [showCreateFileModal, setShowCreateFileModal] = useState(false);
  const [showImportSettingsModal, setShowImportSettingsModal] = useState(false);
  const [Error, open] = useErrorModal();

  const onSubmit = () => {
    setShowCreateFileModal(false);
    importFiles.refreshList();
  };

  const handleFileSubmit = values => {
    // Upload the file
    const data = new FormData();
    data.append('file', values.file[0]);

    return api
      .post('/files', data)
      .then(res => {
        // Add file to import session
        const fileId = _.get(res, 'data.id');
        return importFiles
          .createItem({
            args: {
              importId: imports.item.data.id
            },
            data: {
              file: {
                id: fileId
              },
              type: { id: values.type }
            }
          })
          .then(() => {
            onSubmit();
          });
      })
      .catch(e => {
        open({
          title: 'An Error Occured',
          content: e?.message ?? e
        });
      });
  };

  const handleFileStatusUpdate = () => {
    importFiles.refreshList();
  };

  useEffect(() => {
    echo
      .channel(`import.${getImportId(props)}.files`)
      .listen('.file.updated', handleFileStatusUpdate);

    return () => {
      echo.leaveChannel(`import.${getImportId(props)}.files`);
    };
  });

  if (
    _.get(imports, 'item.status') === 'loading' ||
    _.get(importFiles, 'list.status') === 'loading'
  ) {
    return <LoadingLayout />;
  }

  const item = _.get(imports, 'item.data');
  const files = _.get(importFiles, 'list.items');

  let [completed, errorsAndWarnings, pending] = files.reduce(
    (acc, file) => {
      if (file?.status?.id === 'complete') {
        acc[0].push(file);
      } else if (
        file?.status?.id === 'blocking_error' ||
        file?.status?.id === 'error' ||
        file?.status?.id === 'warning'
      ) {
        acc[1].push(file);
      } else {
        acc[2].push(file);
      }
      return acc;
    },
    [[], [], []]
  );

  const isSubmitted = item.submissions.length > 0;
  const submittedAt = dayjs(item.submissions?.[0]?.created_at).format(
    'DD MMM YYYY'
  );

  return (
    <Fragment>
      <Breadcrumb
        crumbs={[
          {
            label: 'All imports',
            route: '/'
          },
          {
            label: _.get(item, 'description')
          }
        ]}
      />
      <Box
        mb={PADDINGS.M}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Heading>{_.get(item, 'description')}</Heading>
        <Box flexDirection={'row'} alignItems={'center'}>
          {!item?.archived_at && !isSubmitted && (
            <>
              <ButtonContainer>
                <SecondaryButton
                  grey
                  onClick={() => importFiles.refreshList()}
                  isLoading={importFiles.list?.status === 'refreshing'}
                  isDisabled={importFiles.list?.status === 'refreshing'}
                >
                  Refresh
                </SecondaryButton>
                <PrimaryButton
                  grey
                  onClick={() => setShowCreateFileModal(true)}
                >
                  Add new spreadsheet
                </PrimaryButton>
                <SubmitButton
                  importId={imports.item?.data?.id}
                  files={importFiles?.list?.items}
                />
              </ButtonContainer>
            </>
          )}
          {isSubmitted && (
            <Box flexDirection={'column'}>
              <Box
                mb={'1rem'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <SubHeading>Submitted On: {submittedAt}</SubHeading>
                <PrimaryButton onClick={() => setShowImportSettingsModal(true)}>
                  View Settings
                </PrimaryButton>
              </Box>
              <Body>
                Please be advised that it could take up 2-3 business days to
                process.
              </Body>
            </Box>
          )}
        </Box>
      </Box>

      {files.length < 1 && <EmptyView />}

      {pending.length > 0 && (
        <Box>
          <SubHeading>Validation in-progress</SubHeading>
          <List
            items={pending}
            Header={FileHeader}
            renderItem={item => (
              <FileItem isSubmitted={isSubmitted} item={item} />
            )}
          />
        </Box>
      )}

      {errorsAndWarnings.length > 0 && (
        <Box>
          <SubHeading>Action required</SubHeading>
          <List
            items={errorsAndWarnings}
            Header={FileHeader}
            renderItem={item => (
              <FileItem isSubmitted={isSubmitted} item={item} />
            )}
          />
        </Box>
      )}

      {completed.length > 0 && (
        <Box>
          <SubHeading>Completed</SubHeading>
          <List
            items={completed}
            Header={FileHeader}
            renderItem={item => (
              <FileItem isSubmitted={isSubmitted} item={item} />
            )}
          />
        </Box>
      )}

      {showCreateFileModal && (
        <Modal
          closeModal={() => setShowCreateFileModal(false)}
          title={'Create New Import File'}
        >
          <ImportFileForm handleSubmit={handleFileSubmit} />
        </Modal>
      )}

      {showImportSettingsModal && (
        <Modal
          closeModal={() => setShowImportSettingsModal(false)}
          title={'Import Settings'}
          width={'100rem'}
        >
          <List
            items={files}
            Header={() => <SubmissionHeader isSubmitted={isSubmitted} />}
            renderItem={file => (
              <SubmissionBody importFile={file} isSubmitted={isSubmitted} />
            )}
          />
        </Modal>
      )}

      <Error />
    </Fragment>
  );
};

const ImportScreen = compose(
  withQuery(getImportQuery(getImportId)),
  withQuery(listImportFilesQuery(getImportId))
)(_ImportScreen);

export default ImportScreen;
