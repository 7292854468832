/*
|-------------------------------------------------------------------------------
| Global Util Setup
|-------------------------------------------------------------------------------
|
| - Log app configuration at start
| - Setup Promises with better DX, using blubird
|
*/

import config from 'config';
import BluebirdPromise from 'bluebird';

if (!global) {
  // eslint-disable-next-line
  global = global || window;
}

if (global) {
  global.__DEV__ =
    typeof global.__DEV__ !== 'undefined'
      ? global.__DEV__
      : process.env.NODE_ENV !== 'production';
}

if (__DEV__) {
  console.log(
    '\nDevelopment Environment:\n\n\t__DEV__ = true\n' +
      Object.keys(config)
        .map(key => `\t${key} = ${config[key]}`)
        .join('\n')
        .trim()
  );
}

// Replaces Promise with the Bluebird implementation, and adds a global rejection
// handler.
global.Promise = BluebirdPromise;
global.Promise.onPossiblyUnhandledRejection(function (rejectionError) {
  const error = new Error(
    'Promises rejections should always be handled with catch().'
  );
  error.message = `${error.message}\n\n${rejectionError.message}`;
  error.name = `Unhandled Promise Rejection (${rejectionError.name})`;
  error.stack = rejectionError.stack;
  throw error;
});

// Disable warnings for missed returns in Bluebird.
// See https://github.com/petkaantonov/bluebird/issues/903
global.Promise.config({
  warnings: false
});
