import React from 'react';
import VividActionMenu from '@rexlabs/action-menu';
import HorizontalActionButton from 'assets/icons/vertical-dots.svg';

interface Item {
  label: string | React.ReactNode;
  onClick: (...args: any) => any;
}

export interface ActionMenuProps {
  items: Item[];
}

/**
 * @param props
 * @constructor
 */
const ActionMenu = (props: ActionMenuProps) => {
  const renderButton = () => <HorizontalActionButton style={{ width: 20 }} />;
  return (
    <VividActionMenu placement='left-start' Button={renderButton} {...props} />
  );
};

export default ActionMenu;
