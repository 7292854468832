import React, { Fragment, PureComponent } from 'react';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { withQuery } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Tabs } from '@rexlabs/tabs';
import { push } from '@rexlabs/whereabouts';
import List from '@rexlabs/list';

import { COLORS, PADDINGS } from 'src/theme';
import ROUTES from 'src/routes/app';
import { getListIssuesQuery } from 'data/queries/issues';
import { getImportQuery } from 'data/queries/imports';
import { getImportFilesQuery } from 'data/queries/import-files';
import { echo } from 'utils/echo-client';

import LoadingLayout from 'view/layouts/loading';
import Breadcrumb from 'view/components/breadcrumb';
import { SubHeading, Body } from 'view/components/text';
import Issue from 'view/components/issue';
import InstancesModal from 'view/modals/issue-instances';
import EmptyView from 'view/components/list/empty';
import { ValueHeader, ValueItem } from 'view/components/list/values';
import ValidationHeader from 'view/components/validator/header';

function getFileId (props) {
  return _.get(props, 'match.params.fileId');
}

function getImportId (props) {
  return _.get(props, 'match.params.importId');
}

const issuesQuery = getListIssuesQuery(getImportId, getFileId);
const importQuery = getImportQuery(getImportId);
const importFilesQuery = getImportFilesQuery(getImportId, getFileId);

const defaultStyles = StyleSheet({
  wrapHeading: {
    maxWidth: '50rem',
    marginTop: '.6rem'
  },

  icon: {
    marginRight: PADDINGS.S
  },

  content: {
    position: 'sticky',
    top: 0
  },

  link: {
    color: COLORS.BLUE,
    cursor: 'pointer'
  }
});

@withQuery(issuesQuery)
@withQuery(importQuery)
@withQuery(importFilesQuery)
@styled(defaultStyles)
@autobind
class IssuesScreen extends PureComponent {
  state = {
    selectedIssue: null,
    showIssue: null
  };

  componentDidMount () {
    // Connect to socket
    echo
      .channel(
        `import.${getImportId(this.props)}.files.${getFileId(this.props)}`
      )
      .listen('.file.updated', this.handleFileStatusUpdate);
  }

  componentDidUpdate (prevProps, prevState) {
    const { issues, importFiles, whereabouts } = this.props;

    const oldStatus = prevProps?.issues?.list?.status;
    const newStatus = issues?.list?.status;

    const oldTab = prevProps?.whereabouts?.query?.type;
    const newTab = whereabouts?.query?.type;

    if (oldStatus !== newStatus || oldTab !== newTab) {
      const items = issues?.list?.items;
      const errors = items.filter(item => item?.type?.level === 'error');

      const activeTab = newTab || (errors.length ? 'errors' : 'warnings');
      const type = activeTab === 'errors' ? 'error' : 'warning';

      const item = this.props?.issues?.list?.items?.filter(
        item => item?.type?.level === type
      )?.[0];
      this.setState({ selectedIssue: item });
    }

    // Refresh issues if import file has changed
    if (
      importFiles.item.status === 'loaded' &&
      prevProps.importFiles.item?.data?.file?.id &&
      importFiles.item?.data?.file?.id !==
        prevProps.importFiles.item?.data?.file?.id
    ) {
      issues.refreshList({
        id: 'refresh-issues'
      });
    }
  }

  componentWillUnmount () {
    echo.leaveChannel(
      `import.${getImportId(this.props)}.files.${getFileId(this.props)}`
    );
  }

  handleFileStatusUpdate () {
    const { importFiles } = this.props;
    importFiles.refreshItem({
      id: getFileId(this.props),
      args: {
        importId: getImportId(this.props)
      }
    });
  }

  renderTabs (type) {
    const { issues, styles: s } = this.props;
    const { selectedIssue } = this.state;

    const items = issues?.list?.items?.filter?.(
      result => result?.type?.level === type
    );

    return (
      <Box flexDirection='row' alignItems='flex-start'>
        <Box width='30rem' pt={PADDINGS.S}>
          {items.map(issue => (
            <Issue
              key={issue.id}
              onClick={() => this.setState({ selectedIssue: issue })}
              isActive={selectedIssue?.id === issue.id}
              isError={issue.type?.level === 'error'}
              isWarning={issue.type?.level === 'warning'}
              label={issue.type?.description}
              message={issue.type?.fields}
            />
          ))}
        </Box>
        <Box flex={1} p={PADDINGS.S} {...s('content')}>
          {selectedIssue && (
            <Box flexDirection='column' pl={PADDINGS.S}>
              <SubHeading>{selectedIssue.type.description}</SubHeading>
              <Body grey>
                Issues for: <b>{selectedIssue.type.fields || 'N/A'}</b>
              </Body>

              <Box mt={PADDINGS.M} mb={PADDINGS.XS} flexDirection='row'>
                <Body>
                  Showing {selectedIssue.instances.length} out of{' '}
                  {selectedIssue.count}
                </Body>
                {selectedIssue.instances.length !== selectedIssue.count && (
                  <Box ml={PADDINGS.S} {...s('link')}>
                    <Body
                      onClick={() =>
                        this.setState({ showIssue: selectedIssue })
                      }
                    >
                      View more
                    </Body>
                  </Box>
                )}
              </Box>

              <Box width='40rem'>
                <List
                  items={selectedIssue?.instances?.map?.(instance => ({
                    id: instance.id,
                    row: instance.line_number,
                    value: instance.value
                  }))}
                  renderItem={item => <ValueItem item={item} />}
                  Header={() => (
                    <ValueHeader column={selectedIssue.type.fields || 'N/A'} />
                  )}
                  EmptyView={EmptyView}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  renderErrorsTab () {
    return this.renderTabs('error');
  }

  renderWarningsTab () {
    return this.renderTabs('warning');
  }

  render () {
    const { issues, imports, importFiles, whereabouts, match } = this.props;
    const { showIssue } = this.state;

    if (
      issues?.list?.status !== 'loaded' ||
      importFiles?.item?.status !== 'loaded'
    ) {
      return <LoadingLayout />;
    }

    if (importFiles?.item?.data?.status?.id === 'validating') {
      return (
        <Box flexDirection='column' alignItems='center'>
          <LoadingLayout />
          <Body style={{ paddingTop: '1rem' }}>Validating...</Body>
        </Box>
      );
    }

    const isSubmitted = imports?.item?.data?.submissions?.length > 0;

    const items = issues?.list?.items;
    const warnings = items.filter(item => item?.type?.level === 'warning');
    const errors = items.filter(item => item?.type?.level === 'error');

    const importFile = importFiles?.item?.data;

    return (
      <Fragment>
        <Breadcrumb
          crumbs={[
            {
              label: 'All imports',
              route: ROUTES.IMPORTS
            },
            {
              label: imports?.item?.data?.description,
              route: ROUTES.IMPORT,
              params: { id: imports?.item?.data?.id }
            },
            {
              label: importFile?.file?.name
            }
          ]}
        />

        <ValidationHeader
          isSubmitted={isSubmitted}
          importFile={importFiles}
          blockingErrors={importFile?.status?.id === 'blocking_error'}
          errors={!!errors.length}
          warnings={!!warnings.length}
          archivedImport={!!imports?.item?.data?.archived_at}
        />

        {(!!errors.length || !!warnings.length) && (
          <Tabs
            activeTab={
              whereabouts?.query?.type ||
              (errors.length ? 'errors' : 'warnings')
            }
            onChange={({ name }) =>
              push(ROUTES.ISSUES, {
                params: match?.params,
                query: { type: name }
              })
            }
            items={[
              {
                show: !!errors.length,
                name: 'errors',
                label: `${errors.length} ${importFile?.status?.label}s`,
                Tab: this.renderErrorsTab
              },
              {
                show: !!warnings.length,
                name: 'warnings',
                label: `${warnings.length} warnings`,
                Tab: this.renderWarningsTab
              }
            ].filter(tab => tab.show)}
          />
        )}

        {showIssue && (
          <InstancesModal
            closeModal={() => this.setState({ showIssue: null })}
            issue={showIssue}
          />
        )}
      </Fragment>
    );
  }
}

export default IssuesScreen;
