import React from 'react';
import { ImportFile } from 'data/models/types/import-files';
import List from '@rexlabs/list';
import {
  MissingSettingsHeader,
  MissingSettingsBody
} from '../list/missing-settings';
import Header from 'view/components/header';
import HappySvg from 'assets/illustrations/happy.svg';

export interface MissingSettingsProps {
  files: ImportFile[];
}

const MissingSettings = ({ files }: MissingSettingsProps) => {
  return (
    <>
      <Header
        title='Imports need settings'
        subtitle='One last step for this file and we are set. Simply choose one of the options on how you would like us to find duplicates and how to handle them once we found them.'
        Icon={<HappySvg />}
      />

      {files.length > 0 && (
        <List
          items={files}
          Header={MissingSettingsHeader}
          renderItem={(item: ImportFile) => <MissingSettingsBody file={item} />}
        />
      )}
    </>
  );
};

export default MissingSettings;
