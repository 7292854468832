import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, args) => api.get('/imports/archive', args)
    }
  }
};

const ImportGenerator = new Generator('archived-imports', config);
export default ImportGenerator.createEntityModel();
