import React, { useState } from 'react';
import { pick } from 'lodash/fp';
import Box from '@rexlabs/box';
import Modal, { ModalProps } from 'view/components/modal/modal';
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../button';
import { PADDINGS } from 'theme';
import { useErrorModal } from 'src/hooks';

export interface ActionModalProps {
  onConfirm?: () => void;
  children?: React.ReactNode;
}

const ActionModal = (props: ModalProps & ActionModalProps) => {
  const [submitting, setSubmitting] = useState<Boolean>(false);
  const [Error, open] = useErrorModal();

  const modalPropNames = ['title', 'closeModal', 'width', 'color'];
  const modalProps = pick(modalPropNames)(props);

  const confirm = () => {
    props.onConfirm && setSubmitting(true);
    props
      ?.onConfirm?.()
      .then(props.closeModal)
      .catch(e => {
        setSubmitting(false);
        open({
          title: 'An Error Occurred',
          content: e?.message ?? e
        });
      });
  };

  return (
    <>
      <Modal {...modalProps}>
        {props.children && <Box pb={PADDINGS.M}>{props.children}</Box>}
        <ButtonContainer justifyContent={'center'}>
          <SecondaryButton onClick={props.closeModal}>Cancel</SecondaryButton>
          <PrimaryButton
            grey
            isLoading={submitting}
            isDisabled={submitting}
            onClick={confirm}
          >
            Confirm
          </PrimaryButton>
        </ButtonContainer>
      </Modal>
      <Error />
    </>
  );
};

export default ActionModal;
