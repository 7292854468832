import React, { useState } from 'react';
import ErrorModal from 'view/modals/error-modal';

export interface UseErrorArgs {
  title?: string,
  content?: React.ReactNode | string,
  error?: boolean,
  closeText?: string
}

const useErrorModal = () => {
  const [ show, setShow ] = useState<boolean>(false);
  const [ settings, setSettings ] = useState<UseErrorArgs>({
    title: '',
    content: '',
    error: false,
    closeText: 'Close'
  });

  const open = (newSettings: UseErrorArgs) => {
    setShow(true);
    setSettings(newSettings);
  };

  const close = () => setShow(false);

  const Error = () => (
    <>
      {show && (
        <ErrorModal onClose={close} {...settings}>
          {settings.content}
        </ErrorModal>
      )}
    </>
  );

  return [Error, open, close];
};

export default useErrorModal;
