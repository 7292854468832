export default {
  LOGIN: {
    config: {
      path: '/login',
      Component: require('view/screens/login').default
    }
  },

  ACCOUNT_SELECT: {
    config: {
      path: '/account',
      Component: require('view/screens/account-select').default
    }
  },

  FORGOT_PASSWORD: {
    config: {
      path: '/forgot-password',
      Component: require('view/screens/forgot-password').default
    }
  }
};
