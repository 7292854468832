/*
|-------------------------------------------------------------------------------
| App "Theme" for Internal Components
|-------------------------------------------------------------------------------
|
| These theming configurations are suppose to be consumed by `element-style`s
| "StylesProvider". See https://git.io/vNyT4 for more details.
|
| They are kept in a separate module, so we can do fine-grained import-order for
| the App as well as for Storybook Stories.
|
*/

import { StyleSheet } from '@rexlabs/styling';
import * as t from 'theme';

export default {
  Form: StyleSheet({
    form: {
      marginTop: `-${t.PADDINGS.S}`
    }
  }),

  InnerField: StyleSheet({
    field: {
      marginTop: t.PADDINGS.S
    },

    fieldLabel: {
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      fontWeight: t.FONT.WEIGHTS.BOLD,
      padding: `${t.PADDINGS.XXS} 0`,
      display: 'inline-flex'
    },

    error: {
      fontSize: '1.2rem',
      padding: `${t.PADDINGS.XXS} 0`,
      fontWeight: t.FONT.WEIGHTS.BOLD,
      color: t.COLORS.RED,
      display: 'inline-flex'
    }
  }),

  TextInput: StyleSheet({
    container: {
      padding: t.PADDINGS.XS,
      background: t.COLORS.BACKGROUND,
      border: '0 none',
      borderRadius: 0
    },

    containerFocused: {
      boxShadow: `0 0 0 .3rem ${t.COLORS.GREY.LIGHT}`
    },

    suffix: {
      display: 'flex',
      alignItems: 'center'
    }
  }),

  'PasswordInput TextInput': StyleSheet({
    container: {
      padding: t.PADDINGS.XS,
      background: t.COLORS.BACKGROUND,
      border: '0 none',
      borderRadius: 0
    },

    containerFocused: {
      boxShadow: `0 0 0 .3rem ${t.COLORS.GREY.LIGHT}`
    },

    suffix: {
      display: 'block'
    }
  }),

  SelectMenu: StyleSheet({
    container: {
      border: '0 none',
      boxShadow: '0 0 1rem rgba(0, 0, 0, .2)',
      borderRadius: 0
    }
  }),

  SelectOption: StyleSheet({
    container: {
      padding: t.PADDINGS.XS
    },

    isActive: {
      background: t.COLORS.BACKGROUND,
      color: t.COLORS.PRIMARY_DARK
    },

    isSelected: {
      background: t.COLORS.GREEN,
      color: t.COLORS.WHITE
    }
  }),

  Button: StyleSheet({
    container: {
      background: t.COLORS.PRIMARY_DARK,
      color: t.COLORS.WHITE,
      border: '0 none',
      borderRadius: 0,
      padding: t.PADDINGS.S,
      display: 'inline-flex',
      font: 'inherit'
    }
  }),

  Tab: StyleSheet({
    container: {
      display: 'flex',
      flex: '0 0 auto',
      border: '.1rem solid transparent',
      borderRadius: '.4rem .4rem 0 0',
      color: t.COLORS.GREY.DARK,
      fontSize: '1.2rem',
      fontWeight: t.FONT.WEIGHTS.SEMIBOLD,
      borderBottom: 0,
      position: 'relative',
      zIndex: 2
    },

    containerActive: {
      color: t.COLORS.PRIMARY_DARK,
      borderColor: t.COLORS.GREY.LIGHT,
      background: t.COLORS.WHITE
    }
  }),

  TabBar: StyleSheet({
    list: {
      position: 'relative',

      '&:before': {
        content: ' ',
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        left: 0,
        right: 0,
        height: '.1rem',
        width: '100%',
        background: t.COLORS.GREY.LIGHT
      }
    },

    indicator: {
      display: 'none'
    }
  }),

  List: StyleSheet({
    separator: {
      backgroundColor: t.COLORS.GREY.LIGHT
    }
  }),

  Tooltip: StyleSheet({
    clickable: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),

  ActionMenu: StyleSheet({
    menu: {
      minWidth: '5rem'
    },

    menuItem: {
      '> a': {
        padding: `${t.PADDINGS.XXS} ${t.PADDINGS.XXS}`
      },

      '> a:hover': {
        textDecoration: 'none',
        color: t.COLORS.BLUE
      }
    }
  }),

  InputContainer: StyleSheet({
    container: {
      border: '0 none'
    }
  }),

  FileUpload: StyleSheet({
    inner: {
      background: t.COLORS.BACKGROUND,

      '& > div': {
        background: t.COLORS.GREEN,
        color: t.COLORS.WHITE,
        border: '0 none',
        fontWeight: t.FONT.WEIGHTS.SEMIBOLD
      }
    },

    noFileSelected: {
      color: t.COLORS.GREY.MEDIUM
    }
  }),

  'Button LoadingSpinner': StyleSheet({
    svg: {
      height: '2rem',
      width: 'auto'
    }
  }),

  Checkbox: StyleSheet({
    input: {
      '& + label': {
        cursor: 'default',
        outline: 'none',
        height: '1.4rem',
        width: '1.4rem',
        border: `2px solid ${t.COLORS.GREY.MEDIUM}`
      },
      '&:checked + label': {
        backgroundColor: 'inherit',
        borderColor: `${t.COLORS.GREY.DARK}`,
        '&:before': {
          border: `0.16rem solid ${t.COLORS.GREY.DARK}`,
          borderRadius: '50%',
          backgroundColor: `${t.COLORS.GREY.DARK}`,
          top: '50%',
          height: '0.6rem',
          width: '0.6rem'
        }
      }
    }
  })
};
