import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { COLORS, FONT, UTILS } from 'src/theme';

const rowStyles = StyleSheet({});

@styled(rowStyles)
class Row extends PureComponent {
  render () {
    const { styles: s, ...props } = this.props;
    return (
      <Box
        width='100%'
        flexDirection='row'
        alignItems='center'
        {...s('container')}
        {...props}
      />
    );
  }
}

const headerStyles = StyleSheet({
  container: {
    background: COLORS.BACKGROUND,
    fontSize: '1.2rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    textTransform: 'uppercase',
    borderBottom: `.1rem solid ${COLORS.GREY.LIGHT}`,
    padding: '1rem 1.2rem'
  }
});

@styled(headerStyles)
class HeaderCell extends PureComponent {
  render () {
    const { styles: s, ...props } = this.props;
    return <Box {...s('container')} {...props} />;
  }
}

const cellStyles = StyleSheet({
  container: {
    color: COLORS.PRIMARY_DARK,
    padding: '.8rem 1.2rem',

    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },

  containerTruncate: {
    overflow: 'hidden'
  },

  truncate: {
    width: '100%',
    display: 'block',
    ...UTILS.TRUNCATE
  }
});

@styled(cellStyles)
class Cell extends PureComponent {
  render () {
    const { styles: s, truncate, children, ...props } = this.props;
    return (
      <Box {...s('container', { containerTruncate: truncate })} {...props}>
        <span {...s({ truncate })}>{children}</span>
      </Box>
    );
  }
}

export { Row, HeaderCell, Cell };
