import React, { PureComponent } from 'react';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import PasswordInput from '@rexlabs/password-input';
import Box from '@rexlabs/box';
import { createValidationRules } from '@rexlabs/validator';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';

import config from 'src/config';
import { PADDINGS } from 'src/theme';
import sessionModel from 'data/models/custom/session';
import { PrimaryButton } from 'view/components/button';

const validate = createValidationRules({
  email: 'required',
  password: 'required'
});

@withModel(sessionModel)
@autobind
class LoginScreen extends PureComponent {
  handleSubmit (values) {
    const { session } = this.props;
    return session.login({
      email: values.email,
      password: values.password
    });
  }

  render () {
    return (
      <ReactForms
        validate={validate}
        handleSubmit={this.handleSubmit}
        initialValues={{
          email: config.USER?.EMAIL,
          password: config.USER?.PASSWORD
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              Input={TextInput}
              name='email'
              label='email'
              type='email'
              sendImmediate
            />
            <Field
              Input={PasswordInput}
              name='password'
              label='password'
              type='password'
              sendImmediate
            />
            <Box
              flexDirection='row'
              alignItems='center'
              justifyContent='flex-end'
              mt={PADDINGS.M}
            >
              <PrimaryButton
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={submitForm}
              >
                Login
              </PrimaryButton>
            </Box>
          </Form>
        )}
      </ReactForms>
    );
  }
}

export default LoginScreen;
