import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Box from '@rexlabs/box';
import List from '@rexlabs/list';
import { withModel } from '@rexlabs/model-generator';

import { PADDINGS } from 'theme';
import sessionModel from 'data/models/custom/session';
import { Import } from 'data/models/types/imports';

import { SelectInput } from 'view/components/input/select';
import EmptyView from 'view/components/list/empty';
import { Label } from 'view/components/text';
import { ImportHeader, ImportItem } from 'view/components/list/imports';

interface Account {
  account_id: string;
  name: string;
}

export interface importViewProps {
  allAccounts: Account[];
  imports: Import[];
  refreshImports: () => void;
}

interface DecoratorProps {
  session: any;
}

interface SelectTarget {
  value: any;
  label: string;
}

const ImportView = ({
  session,
  allAccounts,
  imports,
  refreshImports
}: importViewProps & DecoratorProps) => {
  const [selectOptions, setSelectOptions] = useState<SelectTarget[]>([]);
  const [filter, setFilter] = useState<SelectTarget | null>();

  const sortImports = _.orderBy(imports, 'created_at', 'desc');

  useEffect(() => {
    // We only want to display the
    const importIds = imports.map((item: Import) => item.rex_account_id);
    const filtered = allAccounts
      .filter((item: Account) =>
        _.includes(importIds, parseInt(item.account_id))
      )
      .map((item: Account) => ({
        value: item.account_id,
        label: item.name
      }));
    setSelectOptions(filtered);
  }, []);

  const handleFilterChange = (e: SelectTarget) => {
    setFilter(e?.value ? e : null);
  };

  return (
    <Box mt={PADDINGS.M}>
      {session.admin && (
        <Box mb={PADDINGS.XS}>
          <Label>Filter Account</Label>
          <SelectInput options={selectOptions} onSelect={handleFilterChange} />
        </Box>
      )}
      <List
        items={
          filter
            ? sortImports.filter(
                item => item.rex_account_id === parseInt(filter.value)
              )
            : sortImports
        }
        Header={ImportHeader}
        renderItem={(sortImports: Import) => (
          <ImportItem item={sortImports} refreshImports={refreshImports} />
        )}
        EmptyView={EmptyView}
      />
    </Box>
  );
};

export default withModel(sessionModel)(ImportView);
