import React from 'react';
import { defaultFilter } from '@rexlabs/select-input';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { PADDINGS } from 'src/theme';
import _ from 'lodash';

export const selectFilter = (options, props) => {
  const filteredOptions = defaultFilter(options, props).filter(
    opt => !opt.isFixture
  );
  const fixtureOptions = options.filter(opt => Boolean(opt.isFixture));
  return filteredOptions.concat(fixtureOptions);
};

export const pluckValue = option => _.get(option, 'value', null);

export const filterOptionsByValue = (options, value, getValue = pluckValue) => {
  const filteredOptions = options.filter(option =>
    _.isArray(value)
      ? value.includes(getValue(option))
      : getValue(option) === value
  );
  return filteredOptions;
};

export const LoadingIndicator = () => (
  <LoadingSpinner
    styles={{ spinner: { paddingLeft: PADDINGS.S } }}
    size={16}
    strokeWidth={3}
  />
);
