import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Link } from '@rexlabs/react-whereabouts';

import { COLORS, PADDINGS } from 'src/theme';
import { Body } from 'view/components/text';

interface Crumb {
  label: string;
  route?: string;
}

interface Props {
  crumbs: Crumb[];
}

interface DecoratorProps {
  styles: (...args: any) => any;
}

const defaultStyles: Record<string, CSSProperties> = {
  container: {
    margin: `${PADDINGS.S} 0 ${PADDINGS.M}`,
    fontSize: '1.4rem',
    textTransform: 'capitalize'
  },

  crumb: {
    fontSize: '1.4rem'
  },

  active: {
    color: COLORS.GREY.DARK,
    fontWeight: 500
  },

  link: {
    color: COLORS.BLUE,
    textDecoration: 'none'
  }
};

const Breadcrumb = ({ styles: s, crumbs }: Props & DecoratorProps) => {
  const formattedCrumbs: ReactNode = crumbs
    .map((crumb: Crumb, index: number, original: Crumb[]) => {
      if (index === original.length - 1) {
        return (
          <span {...s('active')} key={index}>
            {crumb.label}
          </span>
        );
      }
      return (
        <Link key={index} to={crumb.route} params={crumb.params} {...s('link')}>
          <span {...s('crumb')}>{crumb.label}</span>
        </Link>
      );
    })
    .reduce(
      (accumulator: ReactElement, current: ReactElement, i: number): any => [
        accumulator,
        <Box
          style={{ display: 'flex' }}
          flexGrow
          pl={'0.5rem'}
          pr={'0.5rem'}
          key={`crumb-sep-${i}`}
        >
          ›
        </Box>,
        current
      ]
    );

  return (
    <Box flexDirection={'row'} alignItems={'center'} {...s('container')}>
      {formattedCrumbs}
    </Box>
  );
};

export default styled(StyleSheet(defaultStyles))(Breadcrumb);
