import { query } from '@rexlabs/model-generator';
import importsModel from 'data/models/entities/imports';

export const listImportsQuery = query`{
  ${importsModel} (include: "files", limit: 1000) {
    id
    rex_account_id
    description
    type
    notes
    import_files
    submissions
    created_at
    archived_at
    updated_at
    deleted_at
    files
  }
}`;

export const getImportQuery = id => query`{
  ${importsModel} (id: ${id}) {
    id
    rex_account_id
    description
    type
    notes
    import_files
    submissions
    created_at
    archived_at
    updated_at
    deleted_at,
    schedule
  }
}`;
