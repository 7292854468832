import React from 'react';
import { compose } from 'redux';
import Modal from 'view/components/modal/modal';
import Settings from 'view/components/settings';
import { withQuery } from '@rexlabs/model-generator';
import { getImportFilesQuery } from 'data/queries/import-files';
import { getImportQuery } from 'data/queries/imports';
import { Entity } from 'data/models/types';
import { ImportFile } from 'data/models/types/import-files';
import { Import } from 'data/models/types/imports';
import LoadingLayout from 'view/layouts/loading';

function getFileId (props) {
  return props?.match?.params?.fileId;
}

function getImportId (props) {
  return props?.match?.params?.importId;
}

export interface FileSettingsModalProps {
  closeModal: any;
}

interface DecoratorProps {
  importFiles: Entity<ImportFile>;
  imports: Entity<Import>;
}

const _FileSettingsModal = ({
  importFiles,
  imports,
  closeModal
}: FileSettingsModalProps & DecoratorProps) => {
  return (
    <Modal title='File Settings' closeModal={closeModal}>
      {importFiles.item.status !== 'loaded' ? (
        <LoadingLayout />
      ) : (
        <Settings
          onSubmit={closeModal}
          importFile={importFiles.item?.data}
          imports={imports.item?.data}
        />
      )}
    </Modal>
  );
};

const FileSettingsModal = compose(
  withQuery(getImportFilesQuery(getImportId, getFileId)),
  withQuery(getImportQuery(getImportId))
)(_FileSettingsModal);

export default FileSettingsModal;
