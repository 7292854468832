import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { PADDINGS, COLORS } from 'src/theme';
import { Body } from 'view/components/text';

import CoffeeSvg from 'assets/illustrations/coffee.svg';

const defaultStyles = StyleSheet({
  container: {
    background: COLORS.BACKGROUND
  },

  icon: {
    color: 'red',
    height: '9rem',
    width: 'auto',
    marginBottom: PADDINGS.S
  }
});

@styled(defaultStyles)
class EmptyView extends PureComponent {
  static defaultProps = {
    Icon: CoffeeSvg,
    message: 'No items found.'
  };

  render () {
    const { styles: s, Icon, message } = this.props;
    return (
      <Box
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        p='6rem'
        {...s('container')}
      >
        <Icon {...s('icon')} />
        <Body grey>{message}</Body>
      </Box>
    );
  }
}

export default EmptyView;
