import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      trashItem: (type, { ...args }, importId) =>
        api.delete(`/imports/${importId}`),
      updateItem: (type, { ...args }, importId) =>
        api.patch(`/imports/${importId}`, args)
    }
  }
};

const ImportGenerator = new Generator('imports', config);
export default ImportGenerator.createEntityModel();
