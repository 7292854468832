import React from 'react';
import Box from '@rexlabs/box';
import FileUploadInput from '@rexlabs/file-upload-input';

import { PADDINGS } from 'src/theme';
import { createValidationRules } from 'utils/form';
import { ImportFile } from 'data/models/types/import-files';

import { Form, FormField, ReactForms } from 'view/components/form';
import { PrimaryButton } from 'view/components/button';
import { SelectInput } from 'view/components/input/select';

export interface ImportFileFormProps {
  handleSubmit: (values: any, props: any) => Promise<any>;
  importFile?: ImportFile;
}

export interface ImportFileFormValues {
  file: any;
  type: string;
}

const ImportFileForm = ({ handleSubmit, importFile }: ImportFileFormProps) => {
  const fileTypes = {
    acceptTypes: [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    acceptExtensions: ['.csv', '.xlsx']
  };

  const validate = values => {
    const rules = {
      file: 'required',
      type: 'required'
    };
    return createValidationRules(rules)(values);
  };

  return (
    <ReactForms
      handleSubmit={handleSubmit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormField
            label='File Type'
            name='type'
            Input={SelectInput}
            initialValue={importFile?.type.id}
            inputProps={{
              options: [
                {
                  value: 'people',
                  label: 'People'
                },
                {
                  value: 'companies',
                  label: 'Companies'
                },
                {
                  value: 'properties',
                  label: 'Properties'
                },
                {
                  value: 'appraisals',
                  label: 'Appraisals'
                },
                {
                  value: 'match_profiles',
                  label: 'Match Profiles'
                },
                {
                  value: 'notes',
                  label: 'Notes'
                },
                {
                  value: 'reminders',
                  label: 'Reminders'
                }
              ]
            }}
          />
          <FormField
            label={'Spreadsheet'}
            name={'file'}
            Input={FileUploadInput}
            inputProps={{
              ...fileTypes,
              name: 'file',
              shouldAllowMultiple: false,
              maxFileSize: 100000
            }}
          />
          <Box pt={PADDINGS.S} justifyContent={'flex-end'}>
            <PrimaryButton
              grey
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              Upload File
            </PrimaryButton>
          </Box>
        </Form>
      )}
    </ReactForms>
  );
};

export default ImportFileForm;
