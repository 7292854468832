import React, { Component } from 'react';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';

import LoadingLayout from 'view/layouts/loading';
import AuthLayout from 'view/layouts/auth';
import AppLayout from 'view/layouts/app';

@withModel(sessionsModel)
class FrontendApp extends Component {
  render () {
    const { session } = this.props;

    if (!session.ready) {
      return <LoadingLayout />;
    }

    if (!session.apiToken || !session.rexAccountId) {
      return <AuthLayout />;
    }

    return <AppLayout />;
  }
}

export default FrontendApp;
