/*
|-------------------------------------------------------------------------------
| App Setup
|-------------------------------------------------------------------------------
|
| Connects the DOM to our App. Exposes core App data at DEV time.
|
| It also should be the only file that is importing the store - all other usages
| of store are through react connectors or middleware.
|
*/

import 'core-js';
import 'utils/globals';
import config from 'config';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { StylesProvider } from '@rexlabs/styling';
import { LayoutProvider } from '@rexlabs/box';
import { TextProvider } from '@rexlabs/text';
import { store } from 'src/store';
import { api } from 'utils/api-client';
import { initTheme, TEXTS, LAYOUTS } from 'theme';
import FrontendApp from 'view/app';
import Analytics from '@rexlabs/analytics';

// Note: Ensure this is imported last, as it needs to inject styles last.
import COMPONENTS from 'theme/components';

if (__DEV__) {
  window.app = {
    store,
    api,
    Analytics
  };
}

if (config.SEGMENT_ID) {
  Analytics.useSegment(config.SEGMENT_ID);
}

// Setup global parts of theme
initTheme();

class FrontendMount extends Component {
  componentDidMount () {
    window.document.body.classList.add('ready');
  }

  render () {
    return (
      <Provider store={store}>
        <StylesProvider components={COMPONENTS} debug={__DEV__}>
          <LayoutProvider layout={LAYOUTS}>
            <TextProvider text={TEXTS}>
              <FrontendApp />
            </TextProvider>
          </LayoutProvider>
        </StylesProvider>
      </Provider>
    );
  }
}

render(<FrontendMount />, document.getElementById('app'));
