import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';

const style = StyleSheet({
  container: {
    '> *:not(:first-child)': {
      marginLeft: PADDINGS.XS
    }
  }
});

@styled(style)
class ButtonContainer extends PureComponent {
  render () {
    const { styles: s, children, ...rest } = this.props;
    return (
      <Box {...s('container')} flexDirection='row' {...rest}>
        {children}
      </Box>
    );
  }
}

export default ButtonContainer;
