import Validator from 'validatorjs';
import _ from 'lodash';

export function createValidationRules (definitions) {
  let rules = {};
  let attributeNames = {};
  let includes = {};

  for (let key in definitions) {
    const definition = definitions[key];
    if (Array.isArray(definition)) {
      rules[key] = definition[0];
      // ValidatorJS does not have a way of mapping wildcard field names
      // to attributes so this is a workaround to fix that
      if (/(\*)/.test(key)) {
        for (let i = 0; i < 100; i++) {
          attributeNames[`${key.split('.*')[0]}.${i}`] = definition[1];
          includes[`${key.split('.*')[0]}.${i}`] = definition[2];
        }
      } else {
        attributeNames[key] = definition[1];
        includes[key] = definition[2];
      }
    } else {
      rules[key] = definition;
    }
  }

  return values => {
    const validator = new Validator(values, rules);
    validator.setAttributeNames(attributeNames);
    if (validator.fails()) {
      return getErrorMessages(validator);
    } else {
      return {};
    }
  };
}

function getErrorMessages (validator) {
  const errors = validator.errors.all();
  return _.reduce(
    errors,
    (acc, error, field) => {
      // Change the field name in the error msg to proper case.
      // e.g. lotNumber => Lot Number
      const errMsg = _.replace(error[0], field, _.startCase(field));
      _.set(acc, field, errMsg);
      return acc;
    },
    {}
  );
}
