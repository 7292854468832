import { query } from '@rexlabs/model-generator';
import importsModel from 'data/models/entities/archived-imports';

export const listArchivedImportsQuery = query`{
  ${importsModel} (limit: 1000) {
    id
    rex_account_id
    description
    type
    notes
    import_files
    submissions
    created_at
    archived_at
    updated_at
    deleted_at
    files
  }
}`;
