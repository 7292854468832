import { query } from '@rexlabs/model-generator';
import issuesModel from 'data/models/entities/issues';

export const getListIssuesQuery = (importId, fileId) => query`{
  ${issuesModel} (fileId: ${fileId}, importId: ${importId}, limit: 1000) {
    id
    instances
    import
    import_file
    type
    count
  }
}`;
