import React, { useEffect } from 'react';
import { compose } from 'lodash/fp';
import { replace } from '@rexlabs/whereabouts';
import { RenderMatchedRoutes } from '@rexlabs/react-whereabouts';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';

import { PADDINGS } from 'src/theme';
import AUTH_ROUTES from 'src/routes/auth';
import Logo from 'assets/rex-logo.svg';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';

const defaultStyles = StyleSheet({
  container: {
    width: '100vw',
    height: '100vh'
  },

  wrapScreen: {
    width: '100%',
    maxWidth: '40rem',
    padding: PADDINGS.M
  },

  logo: {
    height: '11rem'
  }
});

function AuthLayout ({ styles: s, session }) {
  useEffect(() => {
    NotFound();
  }, [session.apiToken]);

  const NotFound = () => {
    if (session.apiToken && !session.rexAccountId) {
      replace(AUTH_ROUTES.ACCOUNT_SELECT);
      return null;
    }

    replace(AUTH_ROUTES.LOGIN);
    return null;
  };

  return (
    <Box
      {...s('container')}
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <Logo {...s('logo')} />
      <Box {...s('wrapScreen')}>
        <RenderMatchedRoutes routes={AUTH_ROUTES} NotFound={NotFound} />
      </Box>
    </Box>
  );
}

export default compose(
  withModel(sessionsModel),
  styled(defaultStyles)
)(AuthLayout);
