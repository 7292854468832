import React from 'react';
import { Row, Cell, HeaderCell } from './';

const ValueHeader = ({ column }) => {
  return (
    <Row>
      <HeaderCell width='9rem'>Row</HeaderCell>
      <HeaderCell flex={1}>{column || 'Value'}</HeaderCell>
    </Row>
  );
};

const ValueItem = ({ item }) => {
  return (
    <Row>
      <Cell width='9rem'>{item.row}</Cell>
      <Cell flex={1} truncate>
        {item.value}
      </Cell>
    </Row>
  );
};

export { ValueHeader, ValueItem };
