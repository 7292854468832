import React, { useEffect, useState } from 'react';
import Box from '@rexlabs/box';
import { compose } from 'lodash/fp';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';
import { SubHeading } from 'view/components/text';
import { wings } from 'utils/api-client';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS, FONT } from 'theme';
import { Session } from 'data/models/types/auth';

interface DecoratorProps {
  session: Session;
  styles: (...args: any) => any;
}

const defaultStyles = StyleSheet({
  accountName: {
    fontSize: '2rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    color: COLORS.WHITE
  }
});

/**
 * Displays the current Rex account.
 */
const CurrentAccount = ({ styles: s, session }: DecoratorProps) => {
  const [accountName, setAccountName] = useState<string>('');
  useEffect(() => {
    wings('AccountSettings::read', {}).then(res => setAccountName(res?.name));
  }, [session?.rexAccountId]);

  return (
    <Box ml={PADDINGS.L}>
      <SubHeading {...s('accountName')}>{accountName}</SubHeading>
    </Box>
  );
};

export default compose(
  withModel(sessionsModel),
  styled(defaultStyles)
)(CurrentAccount);
