/*
|-------------------------------------------------------------------------------
| App Styling Constants
|-------------------------------------------------------------------------------
|
| The theme of an app encompasses:
|  1. Design Rules
|  2. Style Utilities
|
| These are here so we can apply discipline to styling our app, and avoid
| spaghetti styling code.
|
*/

import { insertCss, reset, keyframes } from '@rexlabs/styling';

export const COLORS = {
  WHITE: '#fff',
  PRIMARY_DARK: '#484848',
  BACKGROUND: '#F6F8FA',

  GREY: Object.assign('#aaa', {
    DARK: '#6D6D6D',
    MEDIUM: '#bebebe',
    LIGHT: '#dfdfdf'
  }),

  RED: Object.assign('#C43A1A', {
    LIGHT: '#F0836A'
  }),

  BLUE: '#3AA6F1',
  YELLOW: '#F4DA80',
  ORANGE: '#F2AE43',
  PINK: '#BA68C8',

  GREEN: Object.assign('#69CEAB', {
    LIGHT: '#C6E8DC'
  })
};

export const PADDINGS = {
  XXS: '.4rem',
  XS: '.8rem',
  S: '1.6rem',
  M: '2.4rem',
  L: '3.2rem',
  XL: '4rem',
  XXL: '4.8rem'
};

export const FONT = {
  WEIGHTS: {
    LIGHT: 300,
    NORMAL: 400,
    SEMIBOLD: 600,
    BOLD: 900
  }
};

export const BORDERS = {};
export const BORDER_RADIUS = {
  INPUT: '.4rem',
  DIALOGS: '.3rem',
  CONTAINER: '.3rem',
  STATUS_BOX: '.7rem',
  BUTTONS: '.4rem'
};

export const EASINGS = {
  MODAL_FADE: 'cubic-bezier(0.175, 0.885, 0.32, 1.15)'
};

export const SHADOWS = {};

export const LAYOUTS = {};

export const TEXTS = {
  RESET: {
    lineHeight: 'inherit'
  },

  HEADING: {
    fontSize: '3.4rem',
    fontWeight: FONT.WEIGHTS.NORMAL
  },

  SUBHEADING: {
    fontSize: '2rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  BODY: {
    fontSize: '1.6rem'
  },

  SMALL: {
    fontSize: '1.4rem'
  },

  LABEL: {
    fontSize: '1.4rem',
    fontWeight: FONT.WEIGHTS.BOLD
  },

  GREY: {
    color: COLORS.GREY.DARK
  }
};

export const UTILS = {
  TRUNCATE: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const KEYFRAMES = {
  FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),
  FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  })
};

export function initTheme () {
  reset();
  insertCss(`
    html {
      font-size: 62.5% !important;
      height: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
    }

    body {
      font-size: 1.6rem;
      line-height: 1.4;
      font-weight: ${FONT.WEIGHTS.NORMAL};
      color: ${COLORS.PRIMARY_DARK};
      font-family: proxima-nova, ProximaNova, helvetica, arial, sans-serif;
    }
    
    *, *:before, *:after {
      box-sizing: inherit;
    }

    p {
      padding-top: ${PADDINGS.XS};
      padding-bottom: ${PADDINGS.XS};
    }
    
    a {
      text-decoration: none;
      font-weight: ${FONT.WEIGHTS.NORMAL};
      color: ${COLORS.BLUE};
    }
  `);
}
