import Echo from 'laravel-echo';
import config from 'config';
import Pusher from 'pusher-js';

const echo = new Echo({
  broadcaster: 'pusher',
  key: config.PUSHER_KEY,
  cluster: config.PUSHER_CLUSTER,
  forceTLS: true,
});

export { echo };
