import React, { Fragment, PureComponent } from 'react';
import { Link } from '@rexlabs/react-whereabouts';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';

import ROUTES from 'src/routes/app';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    textAlign: 'center'
  },

  title: {
    fontSize: '14rem',
    fontWeight: FONT.WEIGHTS.BOLD
  }
});

@styled(defaultStyles)
class NotFoundScreen extends PureComponent {
  render () {
    const { styles: s } = this.props;
    return (
      <Box {...s('container')}>
        <Box {...s('title')}>404</Box>
        <p>
          Go back to the <Link to={ROUTES.EXAMPLE}>example screen</Link>.
        </p>
      </Box>
    );
  }
}

export default NotFoundScreen;
