import React from 'react';
import { compose, filter } from 'lodash/fp';
import { withQuery } from '@rexlabs/model-generator';
import { Link } from '@rexlabs/react-whereabouts';

import { Entity } from 'data/models/types';
import { ImportFile } from 'data/models/types/import-files';
import { listImportFilesQuery } from 'data/queries/import-files';
import LoadingLayout from 'view/layouts/loading';
import MissingSettings from 'view/components/submit/missing-settings';
import ConfirmFiles from 'view/components/submit/confirm-files';
import Breadcrumb from 'view/components/breadcrumb';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import ROUTES from 'src/routes/app';

import { getImportQuery } from 'data/queries/imports';
import { Import } from 'data/models/types/imports';
import { Heading } from 'view/components/text';
import { PrimaryButton, ButtonContainer } from 'view/components/button';

function getImportId (props) {
  return props?.match?.params?.importId;
}

export interface SubmitScreenProps {}

interface DecoratorProps {
  imports: Entity<Import>;
  importFiles: Entity<ImportFile>;
}

const importFilesQuery = listImportFilesQuery(getImportId);
const importQuery = getImportQuery(getImportId);

const SubmitScreen = ({
  imports,
  importFiles
}: SubmitScreenProps & DecoratorProps) => {
  // Loading
  if (
    importFiles.list.status === 'loading' ||
    imports.item.status === 'loading'
  ) {
    return <LoadingLayout />;
  }

  // Check if already submitted
  const submissions = imports.item.data.submissions;
  if (submissions && submissions.length > 0) {
    return (
      <Box flexDirection={'column'} alignItems='center'>
        <Heading>Your files are already submitted</Heading>
        <ButtonContainer pt={PADDINGS.M}>
          <Link to='/'>
            <PrimaryButton>Return to Home</PrimaryButton>
          </Link>
          <Link
            to={ROUTES.IMPORT}
            params={{
              id: imports.item?.data?.id
            }}
          >
            <PrimaryButton>Return to Import</PrimaryButton>
          </Link>
        </ButtonContainer>
      </Box>
    );
  }

  const files = importFiles.list?.items;

  const missingSettings = filter(
    (file: ImportFile) =>
      file.settings === null &&
      !['reminders', 'match_profiles', 'appraisals', 'notes'].includes(
        file.type?.id
      )
  )(files);

  const missingSettingsForHeaders = filter(
    (file: ImportFile) =>
      file.settings === null &&
      ['people', 'companies', 'properties'].includes(file.type?.id)
  )(files);

  const nonMissingSettings = filter(
    (file: ImportFile) =>
      file.settings !== null ||
      ['reminders', 'match_profiles', 'appraisals', 'notes'].includes(
        file.type?.id
      )
  )(files);

  return (
    <>
      <Breadcrumb
        crumbs={[
          {
            label: 'All imports',
            route: '/'
          },
          {
            label: imports.item?.data?.description,
            route: `/import/${imports.item?.data?.id}`
          },
          {
            label: 'Settings'
          }
        ]}
      />

      {missingSettings.length > 0 && (
        <Box mb={PADDINGS.XL}>
          <MissingSettings files={missingSettings} />
        </Box>
      )}

      {nonMissingSettings.length > 0 && (
        <ConfirmFiles
          showHeader={missingSettingsForHeaders.length === 0}
          import={imports.item?.data}
          files={nonMissingSettings}
        />
      )}
    </>
  );
};

export default compose(
  withQuery(importFilesQuery),
  withQuery(importQuery)
)(SubmitScreen);
