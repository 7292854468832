import Generator from 'data/models/generator';

const TYPE = 'connection';

const initialState = {
  isOnline: navigator ? navigator.onLine : false
};

const selectors = {
  isOnline: state => state.connection.isOnline
};

const actionCreators = {
  changeStatus: {
    reduce: (state, action) => ({ ...status, isOnline: action.payload })
  }
};

const connection = new Generator(TYPE).createModel({
  initialState,
  selectors,
  actionCreators
});

export const middleware = ({ dispatch }) => {
  if (navigator) {
    const changeStatus = (...args) =>
      dispatch(connection.actionCreators.changeStatus(...args));

    window.addEventListener('online', () => changeStatus(true));
    window.addEventListener('offline', () => changeStatus(false));
  }

  return next => action => next(action);
};

export default connection;
