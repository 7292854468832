import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { importId, fileId, issueId, ...args }) =>
        api.get(
          `/imports/${importId}/files/${fileId}/issues/${issueId}/instances`,
          args
        )
    }
  }
};

const IssueInstanceGenerator = new Generator('instance', config);
export default IssueInstanceGenerator.createEntityModel({});
