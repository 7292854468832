import React, { useState } from 'react';
import List from '@rexlabs/list';
import { compose } from 'lodash/fp';
import { withModel } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';

import { ImportFile } from 'data/models/types/import-files';
import { PrimaryButton, ButtonContainer } from '../button';
import { SubmissionHeader, SubmissionBody } from '../list/submission';
import Header from 'view/components/header';
import ActionModal from 'view/components/modal/action-modal';
import submissionsModel from 'data/models/entities/submissions';
import importsModel from 'data/models/entities/imports';
import useErrorModal from 'src/hooks/use-error-modal';
import Box from '@rexlabs/box';
import RadioGroup from '..//checkbox/radio-group';
import ROUTES from 'routes/app';

import HappySvg from 'assets/illustrations/happy.svg';
import { Entity } from 'data/models/types';
import { Submission } from 'data/models/types/submissions';
import { Import } from 'data/models/types/imports';

export interface ConfirmFilesProps {
  import: Import;
  files: ImportFile[];
  showHeader: boolean;
}

interface DecoratorProps {
  submissions: Entity<Submission>;
  imports: Entity<Import>;
}

const ConfirmFiles = ({
  import: i,
  files,
  submissions,
  showHeader,
  imports
}: ConfirmFilesProps & DecoratorProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [Error, openError] = useErrorModal();
  const [importType, setImportType] = useState<string>(i.schedule);

  const handleError = (e: Error) =>
    openError({
      title: 'Error',
      content: e.message
    });

  const handleSubmissions = () => {
    return submissions
      .createItem({
        data: {
          import: {
            id: i.id
          }
        }
      })
      .then(() => {
        push(ROUTES.IMPORT, { params: { id: i.id } });
      })
      .catch(handleError);
  };

  const handleImportType = () => {
    if (i.schedule !== importType) {
      return imports
        .updateItem({
          id: i.id,
          args: {
            schedule: importType
          }
        })
        .catch(handleError);
    } else {
    }
  };

  return (
    <>
      {showHeader && (
        <Header
          Icon={<HappySvg />}
          Buttons={
            <ButtonContainer>
              <PrimaryButton grey onClick={() => setOpen(true)}>
                Confirm Submission
              </PrimaryButton>
            </ButtonContainer>
          }
          title='Confirm Submission'
          subtitle='Awesome work! Check below summary of your submission.'
        />
      )}
      <List
        items={files}
        Header={SubmissionHeader}
        renderItem={(item: ImportFile) => <SubmissionBody importFile={item} />}
      />
      {open && (
        <ActionModal
          title='Confirm Submission'
          closeModal={() => setOpen(false)}
          onConfirm={() => {
            handleImportType();
            handleSubmissions();
          }}
        >
          Are you sure you want to submit this import?
          <Box mt={'1rem'}>
            <RadioGroup
              items={[
                {
                  label: (
                    <>
                      Free monthly (These are typically run at the end of each
                      month. Click{' '}
                      <a
                        href='https://support.rexsoftware.com/hc/en-us/articles/360033404693-Monthly-Data-Imports-Free-Paid-'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        here
                      </a>{' '}
                      for more info)
                    </>
                  ),
                  value: 'free'
                },
                {
                  label:
                    'Paid unscheduled ($300+GST with a 2 business day turn around)',
                  value: 'paid'
                }
              ]}
              value={importType}
              onChange={e => setImportType(e.target.value)}
            />
          </Box>
        </ActionModal>
      )}
      <Error />
    </>
  );
};

export default compose(
  withModel(submissionsModel),
  withModel(importsModel)
)(ConfirmFiles);
