import React, { PureComponent } from 'react';
import { withQuery } from '@rexlabs/model-generator';

import { listImportsQuery } from 'data/queries/imports';

import Modal from 'view/components/modal/modal';
import ImportForm from 'view/forms/import';

@withQuery(listImportsQuery)
class CreateImportModal extends PureComponent {
  render () {
    const { closeModal, imports } = this.props;
    return (
      <Modal title='Create an Import Session' closeModal={closeModal}>
        <ImportForm
          onSubmit={() => imports.refreshList()}
          closeModal={closeModal}
        />
      </Modal>
    );
  }
}

export default CreateImportModal;
