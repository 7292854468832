import React, { PureComponent } from 'react';
import types from 'prop-types';
import _ from 'lodash';
import { query, withQuery } from '@rexlabs/model-generator';
import List from '@rexlabs/list';

import instanceModel from 'data/models/entities/instances';

import LoadingLayout from 'view/layouts/loading';
import Modal from 'view/components/modal/modal';

import EmptyView from 'view/components/list/empty';
import { ValueHeader, ValueItem } from 'view/components/list/values';

function getIssueId (props) {
  return _.get(props, 'issue.id');
}

function getImportId (props) {
  return _.get(props, 'issue.import.id');
}

function getFileId (props) {
  return _.get(props, 'issue.import_file.id');
}

const instanceQuery = query`{
  ${instanceModel} (issueId: ${getIssueId}, fileId: ${getFileId}, importId: ${getImportId}, limit: 1000) {
    id
    line_number
    value
  }
}`;

@withQuery(instanceQuery)
class InstanceModal extends PureComponent {
  static propTypes = {
    issue: types.array.isRequired
  };

  renderValue (item) {
    return <ValueItem item={item} />;
  }

  render () {
    const { instance, closeModal } = this.props;

    const items = instance?.list?.items?.map?.(instance => ({
      id: instance.id,
      row: instance.line_number,
      value: instance.value
    }));

    return (
      <Modal
        width={'60rem'}
        closeModal={closeModal}
        title={'All Issues Instances'}
      >
        {instance?.list?.status === 'loading' ? (
          <LoadingLayout />
        ) : (
          <List
            items={items}
            renderItem={this.renderValue}
            Header={ValueHeader}
            EmptyView={EmptyView}
          />
        )}
      </Modal>
    );
  }
}

export default InstanceModal;
