import Generator from '../generator';

const config = {
  entities: {
    api: {}
  }
};

const SessionGenerator = new Generator('submissions', config);
export default SessionGenerator.createEntityModel();
