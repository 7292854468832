import React from 'react';
import Box from '@rexlabs/box';
import {
  SecondaryButton,
  PrimaryButton,
  ButtonContainer
} from 'view/components/button';
import { StyleSheet, styled } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import { Heading, Body } from 'view/components/text';
import { useUpdateImportFileForm } from 'src/hooks';
import { Entity } from 'data/models/types';
import { ImportFile } from 'data/models/types/import-files';
import { Link } from '@rexlabs/react-whereabouts';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';

import HappySvg from 'assets/illustrations/happy.svg';
import NeutralSvg from 'assets/illustrations/neutral.svg';
import SadSvg from 'assets/illustrations/sad.svg';
import { CSSRecord } from 'types';

interface ValidatorHeaderProps {
  importFile: Entity<ImportFile>;
  blockingErrors: boolean;
  errors: boolean;
  warnings: boolean;
  archived: boolean;
  isSubmitted: boolean;
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles: CSSRecord = {
  container: {
    paddingBottom: `${PADDINGS.M}`
  },

  wrapHeading: {
    maxWidth: '45rem',
    marginTop: '.6rem'
  },

  icon: {
    marginRight: PADDINGS.S
  }
};

const ValidatorHeader = ({
  styles: s,
  importFile,
  blockingErrors,
  errors,
  warnings,
  archived,
  isSubmitted
}: ValidatorHeaderProps & DecoratorProps) => {
  const [Modal, setShowReplaceModal] = useUpdateImportFileForm(
    importFile,
    importFile?.item?.data
  );

  const Icon = blockingErrors
    ? SadSvg
    : errors
    ? SadSvg
    : warnings
    ? NeutralSvg
    : HappySvg;

  const statusText = blockingErrors
    ? 'A Blocking error was found and therefore we cannot validate your data as is. ' +
      'Please refer to the ‘Blocking Error’ tab. The errors displayed will need to be rectified before your data can be validated and progress to import.'
    : errors
    ? 'Some rows of data will be skipped due to an error. Please refer to ‘Error’ tab for details. ' +
      'You can fix these errors and re-upload your spreadsheet or accept that these rows will be skipped and progress to import.'
    : warnings
    ? 'There are some non-blocking errors found in your file. Please refer to ‘Warnings’ tab for details. ' +
      'You can fix these warnings and re-upload your spreadsheet or accept some of this data may not be imported as expected.'
    : 'Good news your spreadsheet is ready for import! We highly recommend that your double check your data before progressing.';

  const hasSettings = !!importFile.item.data.settings;

  // Primary file types require settings to import
  const isPrimaryFileTypes = ['people', 'companies', 'properties'].includes(
    importFile.item?.data?.type?.id
  );

  return (
    <Box
      {...s('container')}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box mb={PADDINGS.L} flexDirection={'row'}>
        <Icon {...s('icon')} />
        <Box flexDirection='column' {...s('wrapHeading')}>
          <Heading>File Validation</Heading>
          <Body grey>{statusText}</Body>
        </Box>
      </Box>
      <Box justifyContent={'flex-end'}>
        {!isSubmitted && !archived && (
          <ButtonContainer>
            <SecondaryButton onClick={() => setShowReplaceModal(true)}>
              Update Spreadsheet
            </SecondaryButton>
            {isPrimaryFileTypes && (
              <Link
                to={ROUTES.ISSUES.FILE_SETTINGS}
                params={{
                  importId: importFile.item?.data.import.id,
                  fileId: importFile.item?.data.id
                }}
              >
                {({ onClick }) => (
                  <PrimaryButton grey onClick={onClick}>
                    {hasSettings ? 'Update Settings' : 'Proceed to Settings'}
                  </PrimaryButton>
                )}
              </Link>
            )}
            {(hasSettings || !isPrimaryFileTypes) && (
              <PrimaryButton
                grey
                onClick={() => {
                  push(ROUTES.IMPORT, {
                    params: {
                      id: importFile.item?.data.import.id
                    }
                  });
                }}
              >
                Continue
              </PrimaryButton>
            )}
          </ButtonContainer>
        )}
        {/* Display after submitted for the following spreadsheets: people, companies, and properties */}
        {isSubmitted && isPrimaryFileTypes && (
          <Link
            to={ROUTES.ISSUES.FILE_SETTINGS}
            params={{
              importId: importFile.item?.data.import.id,
              fileId: importFile.item?.data.id
            }}
          >
            {({ onClick }) => (
              <PrimaryButton onClick={onClick}>View Setting</PrimaryButton>
            )}
          </Link>
        )}
        {/* Display after submitted for the following spreadsheets that ARE NOT: people, companies, and properties */}
        {isSubmitted && !isPrimaryFileTypes && (
          <PrimaryButton isDisabled grey>
            No Setting
          </PrimaryButton>
        )}
      </Box>
      <Modal />
    </Box>
  );
};

export default styled(StyleSheet(defaultStyles))(ValidatorHeader);
