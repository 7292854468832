import React, { useEffect, useState } from 'react';
import { noop } from 'lodash/fp';
import Box from '@rexlabs/box';
import Checkbox from './checkbox';

export interface Item {
  label: string | any;
  value: any;
}

export interface RadioGroupProps {
  items: Item[];
  onChange?: (...args: any) => any;
  value?: any;
  disabled?: boolean;
}

const RadioGroup = ({ items, onChange, value, disabled }: RadioGroupProps) => {
  const [checked, setCheckedValue] = useState<number | null>();

  const setChecked = (value: number) => {
    onChange &&
      onChange({
        target: { value: items[value]?.value },
        persist: noop
      });
    setCheckedValue(value);
  };

  useEffect(() => {
    setChecked(items?.findIndex((item: Item) => item.value === value));
  }, [value]);

  return (
    <Box>
      {items?.map((item: Item, index: number) => (
        <Checkbox
          value={checked === index}
          key={index}
          label={item.label}
          onClick={() => !disabled && setChecked(index)}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};

export default RadioGroup;
