import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT, BORDER_RADIUS } from 'src/theme';
import LoadingSpinner from '@rexlabs/loading-spinner';
import _ from 'lodash';

const getStyles = _.memoize(fullWidth =>
  StyleSheet(
    {
      container: {
        background: COLORS.WHITE,
        height: '4.2rem',
        width: fullWidth || 'auto',
        padding: '0 1.2rem',
        border: '1px solid',
        borderColor: COLORS.GREY.MEDIUM,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.5rem',
        fontWeight: FONT.WEIGHTS.SEMIBOLD,
        borderRadius: BORDER_RADIUS.BUTTONS
      },
      content: {
        color: COLORS.GREY.MEDIUM
      }
    },
    `${fullWidth}`
  )
);

class SecondaryButton extends PureComponent {
  render () {
    const { fullWidth } = this.props;
    return (
      <Button
        Loading={() => <LoadingSpinner colors={[COLORS.GREY.MEDIUM]} />}
        styles={getStyles(fullWidth)}
        {...this.props}
      />
    );
  }
}

export default SecondaryButton;
