import React from 'react';
import { Row, Cell, HeaderCell } from './';
import { ImportFile } from 'data/models/types/import-files';
import { PrimaryButton } from '../button';
import FileSettings from '../settings';
import { useModal } from 'src/hooks';

export interface MissingSettingsProps {
  file: ImportFile;
}

const MissingSettingsHeader = () => (
  <Row>
    <HeaderCell width={'13rem'}>Type</HeaderCell>
    <HeaderCell width={'40%'}>Name</HeaderCell>
    <HeaderCell flex={1}>&nbsp;</HeaderCell>
  </Row>
);

const MissingSettingsBody = ({ file }: MissingSettingsProps) => {
  const [SettingModal, openSettingModal, closeSettingModal] = useModal({
    content: (
      <FileSettings onSubmit={() => closeSettingModal()} importFile={file} />
    ),
    title: 'Import Settings'
  });

  return (
    <>
      <Row>
        <Cell width={'13rem'}>{file.type.label}</Cell>
        <Cell width={'40%'}>{file.file.name}</Cell>
        <Cell flex={1} justifyContent='flex-end'>
          <PrimaryButton onClick={() => openSettingModal()}>
            Add Setting
          </PrimaryButton>
        </Cell>
      </Row>
      <SettingModal />
    </>
  );
};

export { MissingSettingsHeader, MissingSettingsBody };
