import React from 'react';
import { compose } from 'lodash/fp';
import Box from '@rexlabs/box';
import VividCheckbox from '@rexlabs/checkbox';
import { Body } from 'view/components/text';
import { styled, StyleSheet } from '@rexlabs/styling';
import { CSSRecord } from 'types';

export interface CheckboxProps {
  value?: boolean;
  label: string;
  onClick: (...args: any) => any;
  disabled?: boolean;
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles: CSSRecord = {
  container: {
    cursor: 'pointer'
  }
};

const Checkbox = ({
  value,
  label,
  onClick,
  disabled,
  styles: s
}: CheckboxProps & DecoratorProps) => {
  return (
    <Box {...s('container')} alignItems={'center'} onClick={onClick}>
      <VividCheckbox value={value} disabled={disabled} />
      <Body>{label}</Body>
    </Box>
  );
};

export default compose(styled(StyleSheet(defaultStyles)))(Checkbox);
