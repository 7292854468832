import React from 'react';
import Box from '@rexlabs/box';
import RadioGroup from '../checkbox/radio-group';
import { FormField, Form, ReactForms } from 'view/components/form';
import { ButtonContainer, PrimaryButton } from '../button';
import { PADDINGS } from 'theme';
import { createValidationRules } from 'utils/form';
import { Entity } from 'data/models/types';
import { ImportFile } from 'data/models/types/import-files';
import { Body } from '../text';
import { withModel } from '@rexlabs/model-generator';
import importFileModel from 'data/models/entities/import-files';
import { Import } from 'data/models/types/imports';

export interface FileSettingsProps {
  importFile: ImportFile;
  imports?: Import;
  onSubmit: any;
}

interface DecoratorProps {
  importFiles: Entity<ImportFile>;
  imports: Entity<Import>;
}

const FileSettings = ({
  onSubmit,
  importFile,
  importFiles,
  imports
}: FileSettingsProps & DecoratorProps) => {
  const needsDupeMethod = (val: any) => ['skip'].includes(val);
  const submitForm = values => {
    return importFiles
      .updateItem({
        id: importFile.id,
        data: {
          importId: importFile.import.id,
          duplicate_detection_type: values?.detectionMethod ?? null,
          handle_duplicates:
            importFile.type?.id !== 'properties'
              ? values?.duplicateMethod
              : values?.duplicateMethodProperties,
          update_record_owners: values?.recordOwnerMethod
        }
      })
      .then(onSubmit);
  };

  const validate = (values: any) =>
    createValidationRules({
      duplicateMethod: 'required',
      detectionMethod: needsDupeMethod(values?.duplicateMethod)
        ? 'required'
        : '',
      duplicateMethodProperties: 'required',
      recordOwnerMethod:
        values?.duplicateMethod === 'update'
          ? 'required'
          : values?.duplicateMethodProperties === 'update'
          ? 'required'
          : ''
    })(values);

  const isUpdate = !!importFile.settings;

  const peopleSettings = [
    {
      label: 'Phone Number',
      value: 'phone_number'
    },
    {
      label: 'Email Address',
      value: 'email_address'
    },
    {
      label: 'Either phone number or email address',
      value: 'both'
    }
  ];

  const companiesSettings = [
    {
      label: 'Company Name',
      value: 'company_name'
    },
    ...peopleSettings
  ];

  const isSubmitted = imports?.submissions && imports?.submissions.length > 0;

  return (
    <>
      <Box mb={PADDINGS.S}>
        <Body>
          Choose one of the options on how we should search for duplicates in
          your import data against your data in Rex. Also, the option of how
          should we handle them once we found duplicates.
        </Body>
      </Box>
      <ReactForms
        handleSubmit={submitForm}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, isSubmitting }) => (
          <Form>
            {['companies', 'people'].includes(importFile.type?.id) && (
              <FormField
                label={
                  'How should we to handle the duplicates if found in your data?'
                }
                name={'duplicateMethod'}
                Input={RadioGroup}
                inputProps={{
                  items: [
                    {
                      label: 'Merge Duplicates',
                      value: 'update'
                    },
                    {
                      label: 'Skip Duplicates',
                      value: 'skip'
                    },
                    {
                      label: 'Insert Duplicates',
                      value: 'insert'
                    }
                  ],
                  disabled: isSubmitted
                }}
                initialValue={importFile.settings?.handle_duplicates}
              />
            )}
            {needsDupeMethod(values?.duplicateMethod) && (
              <FormField
                label={
                  'How do you want us to find duplicates in your import data against your data in Rex?'
                }
                name={'detectionMethod'}
                Input={RadioGroup}
                inputProps={{
                  items:
                    importFile.type?.id === 'people'
                      ? peopleSettings
                      : companiesSettings,
                  disabled: isSubmitted
                }}
                initialValue={importFile.settings?.duplicate_detection_type}
              />
            )}
            {importFile.type?.id === 'properties' && (
              <FormField
                label={
                  'How should we to handle the duplicates if found in your data?'
                }
                name={'duplicateMethodProperties'}
                Input={RadioGroup}
                inputProps={{
                  items: [
                    {
                      label: 'Merge Duplicates',
                      value: 'update'
                    },
                    {
                      label: 'Skip Duplicates',
                      value: 'skip'
                    }
                  ],
                  disabled: isSubmitted
                }}
                initialValue={importFile.settings?.handle_duplicates}
              />
            )}
            {(values?.duplicateMethod === 'update' ||
              values?.duplicateMethodProperties === 'update') && (
              <FormField
                label={
                  'When contacts are being merged, how should we handle the new record owners?'
                }
                name={'recordOwnerMethod'}
                Input={RadioGroup}
                inputProps={{
                  items: [
                    {
                      label: 'Take ownership of existing contacts',
                      value: true
                    },
                    {
                      label: 'Don\'t take ownership of existing contacts',
                      value: false
                    }
                  ],
                  disabled: isSubmitted
                }}
                initialValue={importFile.settings?.update_record_owners}
              />
            )}
            {!isSubmitted && (
              <ButtonContainer mt={PADDINGS.M} justifyContent={'center'}>
                <PrimaryButton
                  grey
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  {isUpdate ? 'Update' : 'Confirm'} Settings
                </PrimaryButton>
              </ButtonContainer>
            )}
          </Form>
        )}
      </ReactForms>
    </>
  );
};

export default withModel(importFileModel)(FileSettings);
