import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from '../../theme/index';
// import { Label } from 'view/components/text';

import {
  withForm,
  Form as RexlabsForm,
  Field,
  ReactForms
} from '@rexlabs/form';

const defaultStyles = StyleSheet({
  errorMessage: {
    marginBottom: PADDINGS.XXS,
    padding: PADDINGS.XS,
    background: COLORS.RED,
    color: COLORS.WHITE
  }
});

@styled(defaultStyles)
class Form extends PureComponent {
  render () {
    const { name, ...props } = this.props;
    return <RexlabsForm id={name} name={name} {...props} />;
  }
}

const fieldStyles = StyleSheet({
  container: {
    borderColor: COLORS.GREY,
    flexShrink: 0
  },
  error: {
    position: 'absolute',
    fontSize: '1.1rem',
    color: COLORS.RED
  },
  field: {
    position: 'relative'
  }
});

class FormField extends PureComponent {
  render () {
    return <Field styles={fieldStyles} {...this.props} />;
  }
}

export { withForm, Form, FormField, ReactForms };
