import React, { PureComponent, Fragment } from 'react';
import { Link } from '@rexlabs/react-whereabouts';

import ROUTES from 'src/routes/auth';

class ForgotPasswordScreen extends PureComponent {
  render () {
    return (
      <Fragment>
        <p>
          This could be a "forgot password" screen, which could allow the user
          to reset their password.
        </p>
        <p>
          Back to <Link to={ROUTES.LOGIN}>login</Link>.
        </p>
      </Fragment>
    );
  }
}

export default ForgotPasswordScreen;
