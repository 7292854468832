import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { fileId, importId, ...args }) =>
        api.get(`/imports/${importId}/files/${fileId}/issues`, args)
    }
  }
};

const IssuesGenerator = new Generator('issues', config);
export default IssuesGenerator.createEntityModel({});
