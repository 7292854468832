export default {
  LOGIN: {
    config: {
      path: '/login',
      redirect: {
        path: '/'
      }
    }
  },

  HOME: {
    config: {
      path: '/',
      exact: true,
      redirect: {
        path: '/imports'
      }
    }
  },

  IMPORTS: {
    config: {
      path: '/imports',
      Component: require('view/screens/imports').default
    },

    CREATE_IMPORT: {
      config: {
        path: '/imports/create',
        Component: require('view/modals/create-import').default
      }
    }
  },

  IMPORT: {
    config: {
      path: '/import/:id',
      exact: true,
      Component: require('view/screens/import').default
    }
  },

  SUBMIT: {
    config: {
      path: '/import/:importId/submit',
      exact: true,
      Component: require('view/screens/submit').default
    }
  },

  ISSUES: {
    config: {
      path: '/import/:importId/file/:fileId',
      Component: require('view/screens/issues').default
    },

    FILE_SETTINGS: {
      config: {
        path: '/import/:importId/file/:fileId/settings',
        Component: require('view/modals/file-settings').default
      }
    }
  }
};
