import React, { useState, useEffect } from 'react';
import Box from '@rexlabs/box';
import { wings } from 'utils/api-client';
import { SelectInput } from 'view/components/input/select';
import { Form, ReactForms, FormField } from 'view/components/form';
import { PrimaryButton } from 'view/components/button';
import { PADDINGS } from 'theme';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';
import { createValidationRules } from 'utils/form';
import { replace } from '@rexlabs/whereabouts';
import ROUTES from 'routes/app';

interface DecoratorProps {
  session: any;
}

interface Account {
  account_id: number;
  name: string;
}

interface SelectItem {
  value: any;
  label: string;
}

const AccountSelectScreen = ({ session }: DecoratorProps) => {
  const [accounts, setAccounts] = useState<SelectItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    wings('UserProfile::getAccessibleAccounts', {}).then(res => {
      const accountList = res.map((a: Account) => ({
        value: a.account_id,
        label: a.name
      }));
      setAccounts(accountList);
      setLoading(false);
    });
  }, []);

  const handleSubmit = values => {
    return session
      .switch({
        rex_account_id: values.account
      })
      .then(() => replace(ROUTES.HOME));
  };

  const validate = createValidationRules({
    account: 'required'
  });

  return (
    <>
      <ReactForms validate={validate} handleSubmit={handleSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form name='upload-file-form'>
            <FormField
              Input={SelectInput}
              name={'account'}
              label={'Select Account'}
              inputProps={{
                debounce: 300,
                isLoading: loading,
                options: accounts
              }}
            />
            <Box justifyContent={'flex-end'} mt={PADDINGS.XS}>
              <PrimaryButton isDisabled={isSubmitting} isLoading={isSubmitting}>
                Select
              </PrimaryButton>
            </Box>
          </Form>
        )}
      </ReactForms>
    </>
  );
};

export default withModel(sessionsModel)(AccountSelectScreen);
