import React, { PureComponent, Fragment } from 'react';
import { RenderMatchedRoutes, Link } from '@rexlabs/react-whereabouts';
import { PortalTarget } from '@rexlabs/portal';
import { withModel } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';

import ROUTES from 'src/routes/app';
import sessionModel from 'data/models/custom/session';
import { PADDINGS, COLORS, FONT } from 'src/theme';
import NotFoundScreen from 'view/screens/not-found';

import LogoWhite from 'assets/rex-logo-white-cropped.svg';
import ModalTarget from 'view/components/portal/modal';
import CurrentAccount from '../components/current-account';

const defaultStyles = StyleSheet({
  heading: {
    width: '100%',
    padding: PADDINGS.S,
    background: COLORS.BLUE
  },

  wrapLogo: {
    display: 'flex'
  },

  logo: {
    height: '5rem',
    width: 'auto'
  },

  logoutLink: {
    fontWeight: FONT.WEIGHTS.BOLD,
    cursor: 'pointer',
    color: COLORS.WHITE
  },

  container: {
    width: '100%'
  },

  innerContainer: {
    width: '100%',
    maxWidth: '100rem',
    padding: PADDINGS.M
  },

  modalBackdrop: {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    transition: 'background .3s'
  },

  modalBackdropActive: {
    background: 'rgba(0, 0, 0, .2)',
    pointerEvents: 'all'
  }
});

@withModel(sessionModel)
@styled(defaultStyles)
class AppShell extends PureComponent {
  render () {
    const { session, styles: s } = this.props;
    return (
      <Fragment>
        <ModalTarget />
        <PortalTarget name='modals'>
          {({ children }) => (
            <>
              {children.map((child, i) => (
                <Box
                  key={i}
                  {...s('modalBackdrop', {
                    modalBackdropActive: !!children.length
                  })}
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                >
                  {child}
                </Box>
              ))}
            </>
          )}
        </PortalTarget>

        <Box
          {...s('heading')}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box flexDirection={'row'} alignItems='center'>
            <Link to={ROUTES.IMPORTS} {...s('wrapLogo')}>
              <LogoWhite {...s('logo')} />
            </Link>
            <CurrentAccount />
          </Box>
          <a onClick={session.logout} {...s('logoutLink')}>
            Logout
          </a>
        </Box>

        <Box {...s('container')} flexDirection='column' alignItems='center'>
          <Box {...s('innerContainer')}>
            <RenderMatchedRoutes routes={ROUTES} NotFound={NotFoundScreen} />
          </Box>
        </Box>
      </Fragment>
    );
  }
}

export default AppShell;
