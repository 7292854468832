import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';
import LoadingSpinner from '@rexlabs/loading-spinner';

import { COLORS, FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    color: COLORS.PRIMARY_DARK,
    fontWeight: FONT.WEIGHTS.BOLD,
    fontSize: '2rem'
  }
});

@styled(defaultStyles)
class LoadingLayout extends PureComponent {
  render () {
    const { styles: s } = this.props;
    return (
      <Box {...s('container')} alignItems='center' justifyContent='center'>
        <Box flexDirection={'column'}>
          <LoadingSpinner />
        </Box>
      </Box>
    );
  }
}

export default LoadingLayout;
