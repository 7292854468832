import React, { useState } from 'react';
import ModalCore from 'view/components/modal/modal';

export interface UseModalArgs {
  title?: string;
  content?: React.ReactNode | string;
  error?: boolean;
  closeText?: string;
}

const useModal = ({ content, ...settings }: UseModalArgs) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const open = () => {
    setOpen(true);
  };

  const close = () => setOpen(false);

  const Modal: React.FC = () => (
    <>
      {isOpen && (
        <ModalCore closeModal={() => setOpen(false)} {...settings}>
          {content}
        </ModalCore>
      )}
    </>
  );

  return [Modal, open, close];
};

export default useModal;
