import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT, BORDER_RADIUS } from 'src/theme';
import LoadingSpinner from '@rexlabs/loading-spinner';
import _ from 'lodash';

const getStyles = _.memoize((colors, fullWidth) =>
  StyleSheet({
    container: {
      background: colors,
      height: '4.2rem',
      padding: '0 1.2rem',
      border: '0 none',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.5rem',
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      borderRadius: BORDER_RADIUS.BUTTONS
    }
  })
);

class PrimaryButton extends PureComponent {
  render () {
    const { grey, red } = this.props;
    const colors = grey ? COLORS.GREY.DARK : red ? COLORS.RED : COLORS.BLUE;
    return (
      <Button
        Loading={() => <LoadingSpinner colors={['white']} />}
        styles={getStyles(colors)}
        {...this.props}
      />
    );
  }
}

export default PrimaryButton;
