import React, { Fragment, PureComponent } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { Link } from '@rexlabs/react-whereabouts';
import { TabsStateful } from '@rexlabs/tabs';

import { PADDINGS } from 'src/theme';
import ROUTES from 'src/routes/app';
import { wings } from 'utils/api-client';
import { listImportsQuery } from 'data/queries/imports';
import { listArchivedImportsQuery } from 'data/queries/archived-imports';
import sessionModel from 'data/models/custom/session';

import LoadingLayout from 'view/layouts/loading';
import PrimaryButton from 'view/components/button/primary';
import { Heading } from 'view/components/text';
import Breadcrumb from 'view/components/breadcrumb';

import ImportView from 'view/components/import-view';

@withQuery(listImportsQuery)
@withQuery(listArchivedImportsQuery)
@withModel(sessionModel)
@autobind
class ImportsScreen extends PureComponent {
  state = {
    archiveImports: [],
    accounts: []
  };

  componentDidMount () {
    if (this.props.session.admin) {
      wings('UserProfile::getAccessibleAccounts', {}).then(res =>
        this.setState({ accounts: res })
      );
    }
  }

  handleFilterChange (e) {
    this.setState({ filter: e.target.value });
  }

  renderActiveTab () {
    const { imports } = this.props;
    return (
      <ImportView
        allAccounts={this.state.accounts}
        imports={imports?.list?.items}
        refreshImports={this.refreshImports}
      />
    );
  }

  renderPastTab () {
    const { archivedImports } = this.props;
    return (
      <ImportView
        allAccounts={this.state.accounts}
        imports={archivedImports?.list?.items}
        refreshImports={this.refreshImports}
      />
    );
  }

  refreshImports () {
    const { imports, archivedImports } = this.props;
    imports.refreshList({ id: 'refresh-imports' });
    archivedImports.refreshList({ id: 'refresh-imports' });
  }

  render () {
    const { imports, archivedImports, session } = this.props;

    if (
      imports?.list?.status !== 'loaded' ||
      archivedImports?.list?.status !== 'loaded'
    ) {
      return <LoadingLayout />;
    }

    return (
      <Fragment>
        <Breadcrumb
          crumbs={[
            {
              label: 'All imports',
              route: '/'
            }
          ]}
        />

        <Box flexDirection={'row'} justifyContent={'space-between'}>
          <Heading>Welcome Back, {session.rexName}</Heading>
          <Link to={ROUTES.IMPORTS.CREATE_IMPORT}>
            {({ onClick }) => (
              <PrimaryButton grey onClick={onClick}>
                Start new import
              </PrimaryButton>
            )}
          </Link>
        </Box>

        <Box pt={PADDINGS.M}>
          <TabsStateful
            items={[
              {
                name: 'active',
                label: 'Active Imports',
                Tab: this.renderActiveTab
              },
              {
                name: 'past',
                label: 'Past Imports',
                Tab: this.renderPastTab
              }
            ]}
          />
        </Box>
      </Fragment>
    );
  }
}

export default ImportsScreen;
