import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { COLORS, PADDINGS, FONT } from 'src/theme';
import { Body, Small } from 'view/components/text';

import WarningSvg from 'assets/icons/warning.svg';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    textAlign: 'left',
    border: '0 none',
    borderLeft: `.3rem solid ${COLORS.WHITE}`,
    cursor: 'pointer',
    padding: PADDINGS.S,
    font: 'inherit',
    '&:focus': {
      boxShadow: '0 none',
      outline: 'none'
    }
  },
  containerActive: {
    backgroundColor: COLORS.BACKGROUND,
    borderColor: COLORS.BLUE
  },
  wrapIcon: {
    marginRight: PADDINGS.XS,
    textAlign: 'center'
  },
  title: {
    fontWeight: FONT.WEIGHTS.NORMAL
  },
  message: {
    fontWeight: FONT.WEIGHTS.LIGHT
  },
  iconWarning: {
    color: COLORS.YELLOW
  },
  iconError: {
    color: COLORS.RED
  }
});

class Issue extends PureComponent {
  static propTypes = {
    isActive: PropTypes.bool,
    isWarning: PropTypes.bool,
    isError: PropTypes.bool,
    percentage: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };

  render () {
    const {
      styles: s,
      isActive,
      isWarning,
      isError,
      label,
      message,
      onClick
    } = this.props;
    return (
      <button
        onClick={onClick}
        {...s('container', { containerActive: isActive })}
      >
        <Box {...s('wrapIcon')}>
          <WarningSvg
            {...s('icon', {
              iconWarning: isWarning,
              iconError: isError
            })}
          />
        </Box>
        <Box flex={1} flexDirection={'column'}>
          <Body {...s('title')}>{label}</Body>
          <Small {...s('message')}>{message}</Small>
        </Box>
      </button>
    );
  }
}

export default styled(defaultStyles)(Issue);
