import React, { useState } from 'react';
import dayjs from 'dayjs/esm';
import { compose } from 'redux';
import { saveAs } from 'file-saver';
import { push } from '@rexlabs/whereabouts';
import { Link } from '@rexlabs/react-whereabouts';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import ROUTES from 'src/routes/app';
import { PADDINGS, COLORS } from 'src/theme';
import { useErrorModal } from 'src/hooks';

import { ImportFile } from 'data/models/types/import-files';
import importFileModel from 'data/models/entities/import-files';
import ActionMenu from 'view/components/action-menu/action-menu';

import { Row, HeaderCell, Cell } from './';
import ActionModal from 'view/components/modal/action-modal';

interface FileItemProps {
  item: ImportFile;
  isSubmitted: boolean;
}

interface DecoratorProps {
  importFiles: any;
  styles: any;
}

const defaultStyles = StyleSheet({
  statusIcon: {
    width: '1rem',
    height: '1rem',
    background: COLORS.GREY.LIGHT,
    marginRight: PADDINGS.XS
  },

  statusComplete: {
    background: COLORS.GREEN
  },

  statusWarning: {
    background: COLORS.ORANGE
  },

  statusError: {
    background: COLORS.RED.LIGHT
  },

  statusBlockingError: {
    background: COLORS.RED
  }
});

export function FileHeader () {
  return (
    <Row>
      <HeaderCell width='13rem'>Type</HeaderCell>
      <HeaderCell flex={1}>File name</HeaderCell>
      <HeaderCell width='16%'>Status</HeaderCell>
      <HeaderCell width='20%'>Uploaded at</HeaderCell>
      <HeaderCell width='6rem'>&nbsp;</HeaderCell>
    </Row>
  );
}

function _FileItem ({
  importFiles,
  item,
  isSubmitted,
  styles: s
}: FileItemProps & DecoratorProps) {
  const [Error, open] = useErrorModal();
  const [deleteModal, setDeleteModal] = useState<Boolean>(false);

  const viewFile = () => {
    push(ROUTES.ISSUES, {
      params: {
        importId: item?.import?.id,
        fileId: item?.id
      }
    });
  };

  const downloadFile = async () => {
    try {
      const importId = item?.import?.id;
      const fileId = item?.id;

      // Get the temp url
      const res = await importFiles.download({
        importId: importId,
        fileId: fileId
      });

      // Download the file from s3
      const blob = await fetch(res.data.url).then(res => res.blob());

      saveAs(blob, item?.file?.name);
    } catch (e) {
      open({
        title: 'An Error Occurred',
        content: e?.message ?? e
      });
    }
  };

  const deleteFile = () =>
    importFiles.trashItem({
      id: item?.id,
      args: {
        importId: item?.import?.id
      }
    });

  const menuItems = isSubmitted
    ? [
        {
          label: 'View',
          onClick: viewFile
        },
        {
          label: 'Download',
          onClick: downloadFile
        }
      ]
    : [
        {
          label: 'View',
          onClick: viewFile
        },
        {
          label: 'Delete',
          onClick: () => setDeleteModal(true)
        },
        {
          label: 'Download',
          onClick: downloadFile
        }
      ];

  return (
    <Link
      to={ROUTES.ISSUES}
      params={{ importId: item?.import?.id, fileId: item?.id }}
    >
      {({ target, onClick }) => (
        <Row Container='a' href={target} onClick={onClick}>
          <Cell width='13rem'>{item?.type?.label}</Cell>
          <Cell flex={1}>{item?.file?.name}</Cell>
          <Cell width='16%'>
            <Box flexDirection='row' alignItems='center'>
              <Box
                {...s('statusIcon', {
                  statusComplete: item?.status?.id === 'complete',
                  statusWarning: item?.status?.id === 'warning',
                  statusError: item?.status?.id === 'error',
                  statusBlockingError: item?.status?.id === 'blocking_error'
                })}
              />
              <Box>{item?.status?.label}</Box>
            </Box>
          </Cell>
          <Cell width='20%'>
            {item?.['updated_at']
              ? dayjs(item.updated_at).format('DD MMM YYYY h:mma')
              : '—'}
          </Cell>
          <Cell
            width='6rem'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ActionMenu items={menuItems} />
          </Cell>
          <Error />
          {deleteModal && (
            <ActionModal
              title='Delete Spreadsheet'
              closeModal={() => setDeleteModal(false)}
              onConfirm={deleteFile}
              color={COLORS.RED}
            >
              Are you sure you want to delete this spreadsheet? This cannot be
              undone.
            </ActionModal>
          )}
        </Row>
      )}
    </Link>
  );
}

export const FileItem = compose(
  withModel(importFileModel),
  styled(defaultStyles)
)(_FileItem);
