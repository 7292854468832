import React from 'react';
import Text from '@rexlabs/text';

const map = obj => Object.keys(obj).filter(k => obj[k]);

export const Heading = props => (
  <Text is='h1' {...props} type={['RESET', 'HEADING']} />
);

export const SubHeading = ({ grey, ...props }) => (
  <Text
    is='h2'
    {...props}
    type={map({ RESET: true, SUBHEADING: true, GREY: grey })}
  />
);

export const Body = ({ grey, ...props }) => (
  <Text is='p' {...props} type={map({ RESET: true, BODY: true, GREY: grey })} />
);

export const Small = ({ grey, ...props }) => (
  <Text
    is='p'
    {...props}
    type={map({ RESET: true, SMALL: true, GREY: grey })}
  />
);

export const Label = ({ grey, ...props }) => (
  <Text is='label' {...props} type={['RESET', 'LABEL']} />
);
